import React, { useCallback, useEffect, useRef, useState } from 'react'

import KeyboardKey, { getKeyboardKey } from 'utils/toolset/keyboard'
import isEmpty from 'utils/toolset/isEmpty'

import type { SupportedKey } from 'utils/toolset/keyboard'

export interface useFocusTrapProps {
  onActivate?: () => void
  onDeactivate?: () => void
  keys?: SupportedKey[]
}

function getFocusedIndexAfterKeyPress(
  e: React.KeyboardEvent,
  currentFocused: number,
  focusableCounter: number
): number {
  const keyHandler: Record<React.KeyboardEvent['key'], (e: React.KeyboardEvent) => number> = {
    Tab(e: React.KeyboardEvent): number {
      return e.shiftKey ? -1 : 1
    },
    ArrowUp() {
      return -1
    },
    ArrowDown() {
      return 1
    },
  }

  function unknownKey() {
    return 0
  }

  const key = getKeyboardKey(e)
  const increment = (keyHandler[key] || unknownKey)(e)

  if (currentFocused === -1 && increment === -1) {
    /**
     * Prevent to skip one element when the initial increment is -1
     * and `currentFocused` is -1, we don't .
     * e.g.: focus has just been activated and user presses arrow up.
     */
    return (increment + focusableCounter) % focusableCounter
  }

  return (currentFocused + increment + focusableCounter) % focusableCounter
}

function getTabbableDescendants<T extends HTMLElement>(container: T | null) {
  if (container == null) {
    return []
  }

  // TODO: add support for other focusable elements
  return Array.from(container.querySelectorAll('button') || [])
}

/**
 * Custom hook that limits the focusable elements within a container, when activated.
 *
 * Based on https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets.
 *
 * @param {useFocusTrapProps} props
 * @param {RefObject} props.containerRef - Ref to the container that will be the boundary for the focus trap.
 * @param {() => void} props.onActivate - Called when the focus trap is activated.
 * @param {() => void} props.onDeactivate - Called when the focus trap is deactivated.
 * @param {(KeyboardEvent['key'])[]} props.keys - Keys that will move the focus inside
 * the container (The current implementation only track focus for button elements). We current
 * support `Tab`, `ArrowUp`, and `ArrowDown` keys. If not provided, only the `Tab` key will be used.
 * @returns {Object}
 */
function useFocusTrap<T extends HTMLElement>(
  props: useFocusTrapProps
): {
  containerRef: (container: T | null) => void
  active: boolean
  activate: () => void
  deactivate: () => void
} {
  const { keys, onActivate, onDeactivate } = props

  const [active, setActive] = useState(false)
  const [currentFocused, setCurrentFocused] = useState(-1)
  const mutationObserverRef = useRef<MutationObserver>()
  const [focusableDescendants, setFocusableDescendants] = useState<HTMLElement[]>([])

  function subscribeToDOMMutationEvents(container: HTMLElement) {
    mutationObserverRef.current = new MutationObserver(function () {
      setFocusableDescendants(getTabbableDescendants(container))
    })

    mutationObserverRef.current.observe(container, { childList: true })
  }

  function unsubscribeToDOMMutationEvents() {
    setCurrentFocused(-1)
    if (mutationObserverRef.current) {
      mutationObserverRef.current?.disconnect()
    }
  }

  const containerRef = useCallback(function initialize(container: HTMLElement | null) {
    if (container != null) {
      setFocusableDescendants(getTabbableDescendants(container))
      subscribeToDOMMutationEvents(container)
    } else {
      unsubscribeToDOMMutationEvents()
    }
  }, [])

  useEffect(function onMount() {
    return function onUnmount() {
      unsubscribeToDOMMutationEvents()
    }
  }, [])

  useEffect(
    function subscribeToKeyEvents() {
      function handleKeyDownEvent(evt: KeyboardEvent) {
        /**
         * Brace yourselves for the nasty casting from globalThis.KeyboardEvent, which is
         * the type used in the `addEventListener` callback, to React.KeyboardEvent ¯\_(ツ)_/¯
         */
        const e = (evt as unknown) as React.KeyboardEvent

        const supportedKeys = [...(keys || ['TAB'])]

        if (!KeyboardKey(e).is(supportedKeys) || isEmpty(focusableDescendants)) {
          return
        }

        e.preventDefault()

        const newCurrentFocused = getFocusedIndexAfterKeyPress(
          e,
          currentFocused,
          focusableDescendants.length
        )
        setCurrentFocused(newCurrentFocused)
        focusableDescendants[newCurrentFocused].focus()
      }

      function subscribe() {
        document.addEventListener('keydown', handleKeyDownEvent)
      }

      function unsubscribe() {
        document.removeEventListener('keydown', handleKeyDownEvent)
      }

      if (active) {
        subscribe()
      }

      return function unsubscribeToKeyEvents() {
        unsubscribe()
      }
    },
    [active, keys, currentFocused, focusableDescendants]
  )

  const activate = useCallback(
    function activate() {
      setActive(true)
      onActivate?.()
    },
    [onActivate]
  )

  const deactivate = useCallback(
    function deactivate() {
      setActive(false)
      onDeactivate?.()
    },
    [onDeactivate]
  )

  return {
    containerRef,
    active,
    activate,
    deactivate,
  }
}

export default useFocusTrap
