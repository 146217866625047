import React from 'react'
import styled from 'styled-components'

import { BaseButton } from 'components/Button'
import { getToken as token } from 'theming'
import { Group, Stack } from 'components/Layout'
import { Icon } from 'components/Icon'
import conditional, { whenProps } from 'tools/conditional'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import MonthHelper from './Month.helper'
import PickerModeToggle from './PickerModeToggle'
import typography from 'styles/typography'
import useCalendar from './useCalendar'
import usePickerMode from './usePickerMode'

import type { CalendarMonthAlias } from './Month.helper'
import type { CalendarProps, GenericCalendarProps } from './Calendar.types'

const Container = styled(Stack)`
  ${typography('body')}
  min-width: calc((7 * 2.5rem) + (6 * ${token('space-2xs')}));
`

const BorderlessButton = styled(BaseButton)`
  ${typography('body-bold')}
  border-color: transparent;
  background-color: transparent;

  color: ${token('color-neutral')};

  inline-size: 40px;

  visibility: ${conditional({
    visible: whenProps({ 'aria-hidden': false }),
    hidden: whenProps({ 'aria-hidden': true }),
  })};

  ${hoverable`
    color: ${token('color-neutral-dark')};
  `}

  ${focusable`
    box-shadow: ${token('button-primary-outline')};
  `}
`

/**
 * Implementation based on
 * * https://medium.com/swlh/build-a-date-picker-in-15mins-using-javascript-react-from-scratch-f6932c77db09
 * * https://codepen.io/zellwk/pen/xNpKwp
 */
export function GenericCalendar(props: GenericCalendarProps): JSX.Element {
  const { calendar, onDayClick, onMonthClick, onYearClick, ...others } = props
  const mode = usePickerMode()

  const { Picker } = mode

  return (
    <Container space="xs" {...others}>
      <Group>
        <BorderlessButton
          type="button"
          aria-label="Navigate to previous month"
          aria-hidden={!mode.is('day-picker')}
          onClick={() => {
            calendar.set({ month: calendar.months[0].month - 1 })
          }}
        >
          <Icon name="caret-left" size={16} />
        </BorderlessButton>

        {calendar.months.map((month) => {
          return (
            <Group key={month.month} space="xs" align="center" justify="center" style={{ flex: 1 }}>
              <PickerModeToggle
                aria-label="Open month selection"
                onClick={() => {
                  mode.dispatch({ type: 'toggle-mode', payload: 'month-picker' })
                }}
                expanded={mode.is('month-picker')}
                disabled={calendar.mode == 'range'}
              >
                {MonthHelper(month.month as CalendarMonthAlias).format('long')}
              </PickerModeToggle>

              <PickerModeToggle
                aria-label="Open year selection"
                onClick={() => {
                  mode.dispatch({ type: 'toggle-mode', payload: 'year-picker' })
                }}
                expanded={mode.is('year-picker')}
                disabled={calendar.mode == 'range'}
              >
                {month.year}
              </PickerModeToggle>
            </Group>
          )
        })}
        <BorderlessButton
          type="button"
          aria-label="Navigate to next month"
          aria-hidden={!mode.is('day-picker')}
          onClick={() => {
            calendar.set({ month: calendar.months[0].month + 1 })
          }}
        >
          <Icon name="caret-right" size={16} />
        </BorderlessButton>
      </Group>

      <Picker
        calendar={calendar}
        mode={mode}
        onDayClick={onDayClick}
        onMonthClick={onMonthClick}
        onYearClick={onYearClick}
      />
    </Container>
  )
}

function Calendar(props: CalendarProps): JSX.Element {
  const { year, month, selected, constraints, onSelect, mode, ...rest } = props
  const calendar = useCalendar({ year, month, selected, constraints, mode, onSelect })

  return <GenericCalendar calendar={calendar} {...rest} />
}

export default Calendar
