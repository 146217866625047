import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import clsx from 'clsx'

import { getToken as token } from 'theming'
import { Group } from 'components/Layout'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import isEmpty from 'utils/toolset/isEmpty'
import toArray from 'utils/toolset/toArray'
import Toggle from './Toggle'
import ToggleGroupContext, { SelectableContext, useSelectable } from './ToggleGroupContext'

import type {
  ToggleGroupOptionProps,
  ToggleGroupProps,
  ToggleOption,
  ToggleGroupOptionValue,
  SelectedToggleGroupOptions,
} from './ToggleGroup.types'

const Container = styled(Group)<{ $multiple: boolean; $scale: ToggleGroupProps['scale'] }>`
  padding: ${conditional({
    '0': whenProps({ $multiple: true }),
    '3px': whenProps({ $multiple: false }),
  })};

  ${({ $multiple }) =>
    $multiple
      ? css`
          border: none;
        `
      : css`
          border: 1px solid ${token('color-neutral-darker')};
          border-radius: ${token('border-radius-s')};
        `}

  ${disableable()}

  ${Toggle} {
    ${conditional({
      'font-size: 10px;': whenProps({ $multiple: false, $scale: 'small' }),
    })};

    height: ${conditional({
      '36px': whenProps({ $multiple: true, $scale: 'default' }),
      '24px': whenProps({ $multiple: true, $scale: 'small' }),
      '28px': whenProps({ $multiple: false, $scale: 'default' }),
      '16px': whenProps({ $multiple: false, $scale: 'small' }),
    })};
  }
`

function fromValueToSelected(
  value: ToggleGroupOptionValue | ToggleGroupOptionValue[]
): ToggleOption[] {
  const valueAsArray = toArray(value)

  return valueAsArray.map((value) => ({ value }))
}

function fromSelectedToValue(
  selected: SelectedToggleGroupOptions,
  multiple: boolean
): ToggleGroupOptionValue | ToggleGroupOptionValue[] | null {
  const pairs = Array.from(selected)

  if (multiple) {
    const value: ToggleGroupOptionValue[] = []
    for (const [, option] of pairs) {
      value.push(option.value)
    }

    return value
  } else {
    if (isEmpty(pairs)) {
      return null
    }
    const [[, option]] = pairs
    return option.value
  }
}

const DEFAULT_SELECTED = [] as ToggleGroupOptionValue[]

function ToggleGroup(props: ToggleGroupProps): JSX.Element {
  const {
    disabled = false,
    multiple = false,
    scale = 'default',
    id,
    name,
    onChange,
    options,
    children,
    className,
    ...others
  } = props
  const selectable = useSelectable({
    multiple,
    selected: fromValueToSelected(props.value ?? DEFAULT_SELECTED),
    onChange: useCallback(
      function handleSelectionChange(selected) {
        onChange?.({
          target: { id, name: name ?? '', value: fromSelectedToValue(selected, multiple) },
        })
      },
      [id, multiple, name, onChange]
    ),
  })

  return (
    <Container
      {...others}
      className={clsx(className, {
        'is-disabled': disabled,
      })}
      id={id}
      role={multiple ? 'group' : 'radiogroup'}
      align="center"
      space={multiple ? 's' : 'xs'}
      $multiple={multiple}
      $scale={scale}
    >
      <ToggleGroupContext.Provider value={{ scale, disabled }}>
        <SelectableContext.Provider value={selectable}>
          {children ??
            (options || []).map(function renderOption(option: ToggleGroupOptionProps) {
              const { label, value, ...others } = option
              const key = String(value)

              return (
                <Toggle
                  key={key}
                  {...others}
                  disabled={Boolean(disabled || option.disabled)}
                  value={value}
                >
                  {label}
                </Toggle>
              )
            })}
        </SelectableContext.Provider>
      </ToggleGroupContext.Provider>
    </Container>
  )
}

ToggleGroup.Option = Toggle

export default ToggleGroup
