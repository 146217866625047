import React from 'react'
import type { ReactNode, HTMLAttributes } from 'react'
import styled from 'styled-components'

import focusable from 'styles/focusable'
import transition from 'styles/transition'
import { getToken as token } from 'theming'
import rem from 'utils/toolset/rem'
import type { MenuLinkProps } from './MenuLink'

export type MenuBaseItemProps = HTMLAttributes<HTMLElement> & {
  label: string
  leading?: ReactNode
  trailing?: ReactNode
  url?: string
  subItems?: MenuLinkProps[]
}

const StyledMenuItem = styled.button`
  ${transition({ duration: '0.25s' })};

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${token('space-s')} 0;
  padding-right: ${rem('12px')};
  padding-left: ${token('space-l')};

  color: ${token('color-neutral-white')};
  font-weight: ${token('font-weight-bold')};
  font-size: ${token('font-size-4')};
  line-height: ${token('font-height-3')};

  cursor: pointer;

  ${focusable`
    color: ${token('color-primary')};
  `}
`

const Leading = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${token('space-s')};
`

const Flex = styled.div`
  display: flex;
`

function MenuBaseItem({ label, leading, trailing, ...props }: MenuBaseItemProps): JSX.Element {
  return (
    <StyledMenuItem {...props}>
      <Flex>
        {leading && <Leading>{leading}</Leading>}
        {label}
      </Flex>
      {trailing && trailing}
    </StyledMenuItem>
  )
}

export default MenuBaseItem
