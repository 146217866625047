import { useEffect, useState } from 'react'

import { useDidMount } from 'hooks/useDidMount'
import toArray from 'utils/toolset/toArray'

import DateHelper, { TODAY } from './Date.helper'
import type { useCalendarProps, useCalendarReturn, RenderableMonth } from './Calendar.types'

function useCalendar(props: useCalendarProps): useCalendarReturn {
  const { constraints, onSelect, mode = 'single' } = props
  const amountOfMonthsToRender = mode == 'single' ? 1 : 2

  const didMount = useDidMount()
  const [selected, setSelected] = useState<ReturnType<typeof getSelectionPair>>(() =>
    getSelectionPair(props.selected)
  )
  const [renderableMonths, setRenderableMonths] = useState<ReturnType<typeof getRenderableMonths>>(
    () => {
      const month = props.month != null ? props.month : TODAY.getMonth()
      const year = props.year != null ? props.year : TODAY.getYear()

      return getRenderableMonths(year, month, amountOfMonthsToRender)
    }
  )

  /**
   * Set the month to be rendered (or initial month when calendar is in range mode).
   * @param {Object} args
   * @param {number} [args.month] - Initial month to be rendered, if not provided, the current initial month will be used.
   * @param {number} [args.year] - Initial year to be rendered, if not provided, the current initial month will be used.
   */
  function set(args: { month?: number; year?: number }) {
    const [initialMonth] = renderableMonths
    const date = new Date(args.year ?? initialMonth.year, args.month ?? initialMonth.month)

    setRenderableMonths(
      getRenderableMonths(date.getFullYear(), date.getMonth(), amountOfMonthsToRender)
    )
  }

  /**
   * Select day or day range.
   * @param range `null` to clear selection, selection range start, or an array with selection start and end.
   */
  function select(range: useCalendarProps['selected']) {
    const [start, end] = getSelectionPair(range)

    function getSelectionBoundary(boundary?: string | number | null): string | null {
      if (boundary != null) {
        return DateHelper(boundary).toString()
      }

      return null
    }

    setSelected([start, end])

    if (didMount) {
      // prevent calling onSelect when initialized with value
      onSelect?.([getSelectionBoundary(start), getSelectionBoundary(end)])
    }
  }

  /**
   * Clear calendar selection.
   */
  function clear() {
    select([null, null])
  }

  useEffect(
    function onMonthOrYearPropsChange() {
      set({
        month: props.month,
        year: props.year,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.month, props.year]
  )

  useEffect(
    function onSelectedPropChange() {
      const newSelected = getSelectionPair(props.selected)

      if (newSelected[0] != selected[0] || newSelected[1] != selected[1]) {
        setSelected(newSelected)
      }
    },
    // we just intend to update the internal state when the prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selected]
  )

  return {
    mode,
    months: renderableMonths,
    selected,
    constraints: toArray(constraints || []),
    clear,
    set,
    select,
  }
}

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000

/**
 * Generate the whole grid of days,
 * @param {number} year
 * @param {number }month
 * @returns {RenderableMonth}
 */
export function getRenderableMonth(year: number, month: number): RenderableMonth {
  const date = DateHelper(Date.UTC(year, month))

  const firstDayOfTheWeek = date.getWeekday()
  const totalDaysInMonth = 32 - new Date(Date.UTC(year, month, 32)).getUTCDate()

  const days = []

  /**
   * Filling days of previous month.
   * If the month does not start on Sunday, then we need to generate only the days of
   * the previous month to fill out the grid.
   */
  for (let index = firstDayOfTheWeek; index > 0; index--) {
    days.push(DateHelper(date.getTime() - index * DAY_IN_MILLISECONDS))
  }

  // Filling days of current month
  for (let index = 0; index < totalDaysInMonth; index++) {
    days.push(DateHelper(date.getTime() + index * DAY_IN_MILLISECONDS))
  }

  /**
   * Filling days of next month, just enough to complete the grid.
   */
  for (let index = 0; (firstDayOfTheWeek + totalDaysInMonth + index) % 7 != 0; index++) {
    days.push(DateHelper(date.getTime() + (totalDaysInMonth + index) * DAY_IN_MILLISECONDS))
  }

  return {
    month: date.getMonth(),
    year: date.getYear(),
    days,
  }
}

/**
 *
 * @param {number} year
 * @param {number} month
 * @param {number?} amount - number of months to be generated. Default value is 1.
 * @returns
 */
export function getRenderableMonths(
  year: number,
  month: number,
  amount = 1
): ReturnType<typeof getRenderableMonth>[] {
  if (month < 0 || month > 11) {
    throw new Error(
      'Invalid month! Please, provide a number between 0 (January) and 11 (December).'
    )
  }

  // TODO: validate accepted year range?

  const months = new Array<ReturnType<typeof getRenderableMonth>>()

  for (let i = 0; i < amount; i++) {
    months.push(getRenderableMonth(year, month + i))
  }

  return months
}

export function getSelectionPair(
  selected?: null | [string | number | null, string | number | null]
): [number | null, number | null] {
  const [start, end] = toArray(selected)

  return [
    start != null ? DateHelper(start).getTime() : null,
    end != null ? DateHelper(end ?? start).getTime() : null,
  ]
}

export default useCalendar
