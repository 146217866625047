import React from 'react'

import getID from 'utils/toolset/getID'

function useID(propId?: string): string {
  const [id, setID] = React.useState(() => propId || getID())

  React.useEffect(
    function updateID() {
      setID(propId || getID())
    },
    [propId]
  )

  return id
}

export default useID
