import React, { ReactNode } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { getToken as token } from 'theming'
import conditional, { whenProps } from 'tools/conditional'
import font from 'styles/font'
import transition from 'styles/transition'

import type { Step } from '../Steps.types'

export interface StepsProgressProps {
  steps: Array<Step>
  current: number | string
}

const StepConnector = styled.span<{ complete: boolean }>`
  position: absolute;
  top: ${conditional({
    'steps-conector-top': whenProps({ complete: false }),
    'steps-conector-top--complete': whenProps({ complete: true }),
  })};
  right: calc(-50% + ${token('steps-indicator-size')} / 2);
  left: calc(50% + ${token('steps-indicator-size')} / 2);

  z-index: 0;

  height: ${conditional({
    'steps-conector-height': whenProps({ complete: false }),
    'steps-conector-height--complete': whenProps({ complete: true }),
  })};

  background: ${conditional({
    'color-neutral': whenProps({ complete: false }),
    'color-accent': whenProps({ complete: true }),
  })};
`

const ProgressStepWrapper = styled.li`
  position: relative;

  flex: 1;

  text-align: center;

  list-style: none;

  &:last-child ${StepConnector} {
    display: none;
  }
`

const ProgressStepContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 ${token('space-s')};
`

const StepIndicator = styled.span<{ current: boolean; complete: boolean }>`
  ${transition()}

  ${font({
    weight: 'font-weight-bold',
    height: 'steps-indicator-size',
  })}

  font-size: ${token('font-size-4')};

  height: ${token('steps-indicator-size')};
  width: ${token('steps-indicator-size')};

  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 2px;
  border-style: solid;
  border-color: ${conditional({
    'color-accent': whenProps([
      { current: true, complete: [true, false] },
      { current: false, complete: true },
    ]),
    'color-neutral': whenProps({ current: false, complete: false }),
  })};
  border-radius: ${token('border-radius-circle')};

  background: ${conditional({
    'color-accent': whenProps([{ current: false, complete: true }]),
    'color-neutral-white': whenProps([
      { current: true, complete: [true, false] },
      { current: false, complete: false },
    ]),
  })};

  color: ${conditional({
    'color-neutral': whenProps({ current: false, complete: false }),
    'color-neutral-white': whenProps({ current: false, complete: true }),
    'color-accent': whenProps({ current: true }),
  })};
`

const StepLabel = styled.span<{ current: boolean; complete: boolean }>`
  ${font({
    height: 'font-height-2',
  })}

  position: relative;

  font-weight: ${conditional({
    'font-weight-medium': whenProps({ current: false }),
    'font-weight-bold': whenProps({ current: true }),
  })};

  color: ${conditional({
    'color-neutral-darker': whenProps([{ current: true }, { current: false, complete: true }]),
    'color-neutral': whenProps({ current: false, complete: false }),
  })};

  font-size: ${token('font-size-4')};

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  text-align: center;

  margin: ${token('space-xs')} 0 0 0;
`

function ProgressStep(props: { step: Step; current: boolean; indicator: ReactNode }): JSX.Element {
  const { step, current, indicator } = props

  return (
    <ProgressStepWrapper>
      <ProgressStepContainer
        data-testid="steps-timeline-entry"
        className={clsx({
          'is-current': current,
          'is-complete': step.complete,
        })}
      >
        <StepIndicator aria-hidden="true" current={current} complete={step.complete}>
          {indicator}
        </StepIndicator>

        <StepLabel current={current} complete={step.complete}>
          {step.label}
        </StepLabel>

        <StepConnector complete={step.complete} />
      </ProgressStepContainer>
    </ProgressStepWrapper>
  )
}

export default ProgressStep
