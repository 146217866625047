import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import MonthHelper from '../Month.helper'
import PickerButton from './PickerButton'

import type { useCalendarReturn, CalendarPickerProps } from '../Calendar.types'
import type { CalendarMonth, CalendarMonthAlias } from '../Month.helper'

const Container = styled.div`
  display: grid;
  grid-auto-rows: 3.75rem;
  grid-gap: ${token('space-2xs')};
  grid-template-columns: repeat(3, 1fr);
`

function getStatus(calendar: useCalendarReturn, month: CalendarMonth) {
  if (calendar.months[0].month == month.index) {
    return 'selection-start'
  }

  return 'default'
}

function CalendarMonthPicker({ calendar, mode }: CalendarPickerProps): JSX.Element {
  return (
    <Container role="radiogroup" data-testid="calendar-month-picker">
      {MonthHelper.getAll().map((month) => (
        <PickerButton
          key={month.index}
          type="button"
          role="radio"
          aria-checked={month.index == calendar.months[0].month}
          aria-label={`Select month ${month.name}`}
          onClick={() => {
            calendar.set({ month: month.index })
            mode.dispatch({ type: 'set-mode', payload: 'day-picker' })
          }}
          $status={getStatus(calendar, month)}
        >
          <time dateTime={`${calendar.months[0].year}-${month.index + 1}`}>
            {MonthHelper(month.name as CalendarMonthAlias).format('short')}
          </time>
        </PickerButton>
      ))}
    </Container>
  )
}

export default CalendarMonthPicker
