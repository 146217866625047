import DateFormatHelper from './DateFormat.helper'
import DateHelper from './Date.helper'

export const formatter = DateFormatHelper('MM/DD/YYYY')

export function getSelection(
  selection?: number | string | null | [number | string | null, number | string | null]
): [null | number, null | number] {
  const [start, end] = Array.isArray(selection) ? selection : [selection, selection]

  return [
    start != null ? DateHelper(start).getTime() : null,
    end != null ? DateHelper(end).getTime() : null,
  ]
}
