import React, { BaseHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { getToken as token } from 'theming'
import conditional, { whenProps } from 'tools/conditional'
import Status from 'utils/types/Status'

const Bar = styled.div<{ rounded: boolean }>`
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;

  justify-content: center;
  overflow: hidden;
  width: 100%;

  position: relative;

  height: ${token('progressbar-height')};

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: ${token('progressbar-height')};
    `}
`

const Line = styled.span`
  width: 100%;
  height: 1px;

  background: ${token('progressbar-line')};
  background: black;
`

type ProgressProps = Pick<ProgressBarProps, 'value' | 'rounded' | 'status'>
const Progress = styled.span<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: ${({ value }) => value}%;
  height: ${token('progressbar-height')};

  background: ${conditional({
    'progressbar-neutral-fill': whenProps({ status: Status.Neutral }),
    'progressbar-success-fill': whenProps({ status: Status.Success }),
    'progressbar-warning-fill': whenProps({ status: Status.Warn }),
    'progressbar-danger-fill': whenProps({ status: Status.Danger }),
  })};
  border-radius: ${conditional({
    ['progressbar-height']: whenProps({ rounded: true }),
  })};

  transition: 600ms ease;
`

export interface ProgressBarProps extends BaseHTMLAttributes<HTMLDivElement> {
  value?: number
  status?: string
  rounded?: boolean
  showLine?: boolean
}

export function ProgressBar({
  value = 60,
  status = Status.Neutral,
  rounded = false,
  showLine = false,
  ...extra
}: ProgressBarProps): JSX.Element {
  const clampedValue = Math.max(0, Math.min(value, 100))

  return (
    <Bar
      role="progressBar"
      aria-valuenow={clampedValue}
      aria-valuemin={0}
      aria-valuemax={100}
      rounded={rounded}
      {...extra}
    >
      {showLine && <Line />}
      <Progress value={clampedValue} rounded={rounded} status={status} />
    </Bar>
  )
}

export default ProgressBar
