import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import DefaultPickerButton from './PickerButton'
import range from 'utils/toolset/range'

import type { useCalendarReturn, CalendarPickerProps } from '../Calendar.types'

const Container = styled.div`
  display: grid;
  grid-auto-rows: 2.875rem;
  grid-gap: ${token('space-2xs')};
  grid-template-columns: repeat(4, 1fr);
`

const PickerButton = styled(DefaultPickerButton)`
  block-size: 46px;
`

function getStatus(calendar: useCalendarReturn, year: number) {
  if (calendar.months[0].year == year) {
    return 'selection-start'
  }

  return 'default'
}

function CalendarYearPicker({ calendar, mode }: CalendarPickerProps): JSX.Element {
  return (
    <Container role="radiogroup" data-testid="calendar-year-picker">
      {range(2012, 2032).map((year) => (
        <PickerButton
          key={year}
          type="button"
          role="radio"
          aria-checked={year == calendar.months[0].year}
          aria-label={`Select year ${year}`}
          onClick={() => {
            calendar.set({ year })
            mode.dispatch({ type: 'set-mode', payload: 'month-picker' })
          }}
          $status={getStatus(calendar, year)}
        >
          {year}
        </PickerButton>
      ))}
    </Container>
  )
}

export default CalendarYearPicker
