import { useRef, useEffect } from 'react'

/**
 * Hook useful to trigger action conditionally based
 * on if the component is mounted.
 * Be aware that your effect, callback, memo, etc cannot list is
 * as a dependency, otherwise it will be triggered at the wrong moment.
 * @returns {boolean} Whether the component is mounted or not.
 */
function useDidMount(): boolean {
  const counter = useRef(0)

  useEffect(function onMount() {
    counter.current = 1
  }, [])

  return counter.current > 0
}

export default useDidMount
