import React, {
  HTMLAttributes,
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  ReactNode,
} from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { TabContext } from './TabsContext'
import conditional, { whenProps } from 'tools/conditional'
import font from 'styles/font'
import hoverable from 'styles/hoverable'
import disableable from 'styles/disableable'
import transition from 'styles/transition'
import useID from 'hooks/useID'

import type { WithDirectionProps, WithActiveProps } from './Tabs.types'
import clsx from 'clsx'

const TabsItemsWrapper = styled.ul`
  display: flex;

  padding: 0;

  border-bottom-color: ${token('color-neutral-lighter')};
  border-bottom-width: 2px;
  border-bottom-style: solid;
`

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`

const Children = styled(StyledSpan)`
  /* placeholder */
`

const Leading = styled(StyledSpan)`
  /* placeholder */
`

const StyledLabel = styled.label<WithActiveProps & WithDirectionProps>`
  ${font({
    height: 'font-height-2',
    weight: 'font-weight-bold',
  })}
  ${transition()}

  font-size: ${token('font-size-4')};
  text-transform: uppercase;

  ${hoverable`
    color: ${token('color-neutral-darker')};
    background-color: ${token('color-neutral-light')}33;
  `}

  ${disableable`
    color: ${token('color-neutral-dark')}33;
    background-color: ${token('color-neutral-light')}33;
  `}

  padding: ${token('space-m')} ${token('space-l')};
  display: inline-flex;
  flex-direction: ${conditional({
    row: whenProps({ direction: 'horizontal' }),
    column: whenProps({ direction: 'vertical' }),
  })};

  cursor: pointer;

  position: relative;
  bottom: -2px;

  border-bottom-width: 2px;
  border-bottom-style: solid;

  border-color: ${conditional({
    'color-transparent': whenProps({ active: false }),
    'color-primary': whenProps({ active: true }),
  })};

  color: ${conditional({
    'color-neutral': whenProps({ active: false }),
    'neutral-darker': whenProps({ active: true }),
  })};

  ${Leading} + ${Children} {
    margin: ${conditional({
        '0': whenProps({ direction: 'horizontal' }),
        'space-2xs': whenProps({ direction: 'vertical' }),
      })}
      0 0
      ${conditional({
        'space-s': whenProps({ direction: 'horizontal' }),
        '0': whenProps({ direction: 'vertical' }),
      })};
  }
`

type TabsItemsProps = HTMLAttributes<HTMLUListElement>

function TabsItems({ children, ...props }: TabsItemsProps) {
  return (
    <TabsItemsWrapper {...props} role="tablist">
      {children}
    </TabsItemsWrapper>
  )
}

interface TabsItemProps extends HTMLAttributes<HTMLInputElement> {
  name: string
  default?: boolean
  leading?: ReactNode
  disabled?: boolean
}

function TabsItem({
  children,
  name,
  default: isDefault,
  className,
  onChange,
  leading,
  disabled = false,
  ...props
}: TabsItemProps) {
  const { id: tabId, setActiveTab, activeTab, direction } = useContext(TabContext)

  const isActive = activeTab === name

  function handleSelect(event: ChangeEvent<HTMLInputElement>) {
    setActiveTab(name)
    onChange?.(event)
  }

  useEffect(() => {
    if (!activeTab && isDefault) {
      setActiveTab(name)
    }
  }, [activeTab, name, setActiveTab, isDefault])

  return (
    <li role="tab" aria-selected={isActive ? 'true' : 'false'}>
      <StyledLabel
        className={clsx(
          {
            'is-disabled': disabled,
          },
          className
        )}
        active={activeTab === name}
        direction={direction}
      >
        <input
          {...props}
          value={name}
          checked={isActive}
          disabled={disabled}
          type="radio"
          name={tabId}
          onChange={handleSelect}
          hidden
        />
        {leading && <Leading>{leading}</Leading>}
        {children && <Children>{children}</Children>}
      </StyledLabel>
    </li>
  )
}

type PanelsItemsProps = HTMLAttributes<HTMLDivElement>

const PanelsItemsWrapper = styled.div`
  /* placeholder */
`

const PanelsItemWrapper = styled.article`
  display: ${conditional({
    block: whenProps({ 'aria-hidden': false }),
    none: whenProps({ 'aria-hidden': true }),
  })};
`

function PanelsItems({ children, ...props }: PanelsItemsProps) {
  return <PanelsItemsWrapper {...props}>{children}</PanelsItemsWrapper>
}

interface PanelsItemProps extends HTMLAttributes<HTMLElement> {
  name: string
}

function PanelsItem({ children, name, ...props }: PanelsItemProps) {
  const { activeTab } = useContext(TabContext)

  const hidden = activeTab !== name

  return (
    <PanelsItemWrapper {...props} role="tabpanel" aria-hidden={hidden}>
      {children}
    </PanelsItemWrapper>
  )
}

export interface TabsProps extends HTMLAttributes<HTMLElement>, WithDirectionProps {
  activeTab?: string
  onTabChange?: (tab: string) => void
}

const TabsWrapper = styled.div`
  ${TabsItemsWrapper} + ${PanelsItemsWrapper} {
    margin: ${token('space-xl')} 0 0 0;
  }
`

function Tabs({
  children,
  direction = 'horizontal',
  id: propId,
  activeTab: propTab = '',
  onTabChange,
  ...props
}: TabsProps): JSX.Element {
  const [activeTab, setActiveTab] = useState(propTab)
  const id = useID(propId)

  useEffect(() => {
    if (propTab) {
      setActiveTab(propTab)
    }
  }, [propTab])

  useEffect(() => {
    onTabChange?.(activeTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  return (
    <TabsWrapper {...props}>
      <TabContext.Provider value={{ activeTab, setActiveTab, id, direction }}>
        {children}
      </TabContext.Provider>
    </TabsWrapper>
  )
}

Tabs.Items = TabsItems
Tabs.Item = TabsItem
Tabs.Panels = PanelsItems
Tabs.Panel = PanelsItem

export default Tabs
