/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'

import conditional, { whenProps } from 'tools/conditional'
import { getToken as token } from 'theming'
import { Icon as DefaultIcon } from 'components/Icon'

import { BaseButton } from 'components/Button'
import DefaultCloseButton from 'common/CloseButton'

export interface BannerProps {
  className?: string
  variant?: 'success' | 'danger' | 'warning' | 'neutral'
  icon?: JSX.Element
  scale?: 'default' | 'large'
  title?: string | ReactElement
  description?: string | ReactElement
  onClose?: () => void
  dismissible?: boolean
}

export interface BannerActionProps extends BannerProps {
  action?: string
  secondaryAction?: string
  onActionButtonClick?: () => void
  onSecondaryActionButtonClick?: () => void
}

interface VariantProps {
  variant?: 'success' | 'danger' | 'warning' | 'neutral'
}

const Description = styled.div`
  color: ${token('banner-description-color')};
  font-size: ${token('banner-font-size')};
  line-height: ${token('banner-font-height')};
  text-align: left;
`

const Title = styled.div<VariantProps>`
  font-weight: ${token('banner-title-font-weight')};
  font-size: ${token('banner-font-size')};
  line-height: ${token('banner-font-height')};
  text-align: left;
`

const TextSection = styled.div<{ $dismissible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  padding-right: ${conditional({
    'space-xl': whenProps({ $dismissible: true }),
  })};
`

const StyledBanner = styled.div<Omit<BannerProps, 'dismissible'>>`
  position: relative;

  display: flex;
  flex-wrap: ${conditional({
    wrap: whenProps({ scale: 'default' }),
    'no-wrap': whenProps({ scale: 'large' }),
  })};
  align-items: center;
  min-width: ${token('banner-min-width')};

  min-height: ${conditional({
    'banner-default-height': whenProps({ scale: 'default' }),
  })};
  padding: ${conditional({
      'banner-default-padding-y': whenProps({ scale: 'default' }),
      'banner-large-padding': whenProps({ scale: 'large' }),
    })}
    ${conditional({
      'banner-default-padding-x': whenProps({ scale: 'default' }),
      'banner-large-padding': whenProps({ scale: 'large' }),
    })};

  color: ${conditional({
    'banner-title-color-success': whenProps({ variant: 'success' }),
    'banner-title-color-danger': whenProps({ variant: 'danger' }),
    'banner-title-color-warning': whenProps({ variant: 'warning' }),
    'banner-title-color-neutral': whenProps({ variant: 'neutral' }),
  })};

  background: ${conditional({
    'banner-background-success': whenProps({ variant: 'success' }),
    'banner-background-warning': whenProps({ variant: 'warning' }),
    'banner-background-danger': whenProps({ variant: 'danger' }),
    'banner-background-neutral': whenProps({ variant: 'neutral' }),
  })};

  border-color: ${conditional({
    'banner-border-color-success': whenProps({ variant: 'success' }),
    'banner-border-color-warning': whenProps({ variant: 'warning' }),
    'banner-border-color-danger': whenProps({ variant: 'danger' }),
    'banner-border-color-neutral': whenProps({ variant: 'neutral' }),
  })};
  border-style: solid;
  border-width: ${token('banner-border-width')};

  border-radius: ${token('banner-border-radius')};
`

export const CloseButton = styled(DefaultCloseButton)`
  position: absolute;
  top: ${conditional({
    'banner-default-close-top': whenProps({ scale: 'default' }),
    'banner-large-close-top': whenProps({ scale: 'large' }),
  })};
  right: ${conditional({
    'banner-default-close-right': whenProps({ scale: 'default' }),
    'banner-large-close-right': whenProps({ scale: 'large' }),
  })};

  width: ${token('banner-icon-width')};
  height: ${token('banner-icon-height')};
`

const IconWrapper = styled.span<{ scale: string }>`
  display: inline-flex;
  align-items: center;

  align-self: ${conditional({
    'banner-default-icon-alignment': whenProps({ scale: 'default' }),
    'banner-large-icon-alignment': whenProps({ scale: 'large' }),
  })};
  justify-content: center;

  margin-right: ${token('banner-icon-margin-right')};
`

export const Icon = styled(DefaultIcon)`
  width: ${token('banner-icon-width')};
  height: ${token('banner-icon-height')};
`

const Button = styled(BaseButton)`
  min-width: 88px;
  margin-top: ${token('space-s')};
  margin-left: ${conditional({
    '0': whenProps({ variant: 'primary' }),
    'banner-secondary-button-margin-left': whenProps({ variant: 'secondary' }),
  })};

  color: ${conditional({
    'banner-button-text-color': whenProps({ variant: 'primary' }),
    'banner-button-background-danger': whenProps({ variant: 'secondary', className: 'danger' }),
    'banner-button-background-warning': whenProps({ variant: 'secondary', className: 'warning' }),
    'banner-button-background-neutral': whenProps({ variant: 'secondary', className: 'neutral' }),
    'banner-button-background-success': whenProps({ variant: 'secondary', className: 'success' }),
  })};

  background-color: ${conditional({
    'banner-button-background-success': whenProps({ variant: 'primary', className: 'success' }),
    'banner-button-background-danger': whenProps({ variant: 'primary', className: 'danger' }),
    'banner-button-background-warning': whenProps({ variant: 'primary', className: 'warning' }),
    'banner-button-background-neutral': whenProps({ variant: 'primary', className: 'neutral' }),
    'banner-button-background-secondary': whenProps({ variant: 'secondary' }),
  })};

  border-color: ${conditional({
    'banner-button-background-success': whenProps({ className: 'success' }),
    'banner-button-background-danger': whenProps({ className: 'danger' }),
    'banner-button-background-warning': whenProps({ className: 'warning' }),
    'banner-button-background-neutral': whenProps({ className: 'neutral' }),
  })};
`
const InlineButton = styled(Button)`
  flex-shrink: 0;
  align-self: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: -${token('space-s')};
  margin-right: 0;
  margin-left: auto;
`

const LargeButtonWrapper = styled.div`
  display: flex;
  margin-left: 0;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${token('space-s')};
`

const getAlertIcon = (variant: string, scale: string, icon?: JSX.Element) => {
  if (icon) {
    return <IconWrapper scale={scale}>{icon}</IconWrapper>
  }

  let iconName = ''
  switch (variant) {
    case 'success':
      iconName = 'check'
      break
    case 'danger':
    case 'warning':
      iconName = 'warning'
      break
    default:
      iconName = 'information'
  }

  return (
    <IconWrapper scale={scale}>
      <Icon name={iconName} size={24} />
    </IconWrapper>
  )
}

function Banner({
  scale = 'default',
  variant = 'neutral',
  icon,
  title,
  description,
  onClose,
  dismissible = true,
  ...others
}: BannerProps) {
  const [visible, setVisible] = useState<boolean>(true)

  if (!visible) return null

  return (
    <StyledBanner {...others} scale={scale} variant={variant}>
      <TextWrapper>
        {getAlertIcon(variant, scale, icon)}
        <TextSection $dismissible={dismissible}>
          {scale === 'default' ? (
            React.isValidElement(title) ? (
              title
            ) : (
              <Title variant={variant}>{title}</Title>
            )
          ) : (
            <>
              {React.isValidElement(title) ? title : <Title>{title}</Title>}
              {description &&
                (React.isValidElement(description) ? (
                  description
                ) : (
                  <Description>{description}</Description>
                ))}
            </>
          )}
        </TextSection>
      </TextWrapper>
      {dismissible && (
        <CloseButton
          scale={scale}
          onClick={() => {
            onClose?.()
            setVisible(false)
          }}
          type="button"
          data-testid="close-button"
        >
          <Icon name="close" size={20} />
        </CloseButton>
      )}
    </StyledBanner>
  )
}

export function BannerLarge({
  scale = 'large',
  variant = 'neutral',
  icon,
  title,
  description,
  onClose,
  dismissible = true,
  ...others
}: BannerProps) {
  const [visible, setVisible] = useState<boolean>(true)

  if (!visible) return null

  return (
    <StyledBanner {...others} scale={scale} variant={variant}>
      {getAlertIcon(variant, scale, icon)}
      <TextSection $dismissible={dismissible}>
        {React.isValidElement(title) ? title : <Title>{title}</Title>}
        {React.isValidElement(description) ? description : <Description>{description}</Description>}
      </TextSection>
      {dismissible && (
        <CloseButton
          scale={scale}
          onClick={() => {
            onClose?.()
            setVisible(false)
          }}
          type="button"
        >
          <Icon name="close" size={16} />
        </CloseButton>
      )}
    </StyledBanner>
  )
}

export function BannerAction({
  scale = 'default',
  variant = 'neutral',
  icon,
  title,
  action,
  secondaryAction,
  onActionButtonClick,
  onSecondaryActionButtonClick,
  ...others
}: BannerActionProps): JSX.Element {
  return (
    <StyledBanner {...others} scale={scale} variant={variant}>
      <TextWrapper>
        {getAlertIcon(variant, scale, icon)}
        <TextSection>
          {React.isValidElement(title) ? title : <Title variant={variant}>{title}</Title>}
        </TextSection>
      </TextWrapper>
      <ButtonWrapper>
        <InlineButton
          scale="small"
          variant="primary"
          className={variant}
          type="button"
          onClick={onActionButtonClick}
        >
          {action}
        </InlineButton>
        {secondaryAction && (
          <InlineButton
            scale="small"
            className={variant}
            variant="secondary"
            type="button"
            onClick={onSecondaryActionButtonClick}
          >
            {secondaryAction}
          </InlineButton>
        )}
      </ButtonWrapper>
    </StyledBanner>
  )
}

export function BannerActionLarge({
  scale = 'large',
  variant = 'neutral',
  icon,
  title,
  description,
  action,
  secondaryAction,
  onActionButtonClick,
  onSecondaryActionButtonClick,
  onClose,
  dismissible = false,
  ...others
}: BannerActionProps) {
  const [visible, setVisible] = useState<boolean>(true)

  if (!visible) return null

  return (
    <StyledBanner {...others} scale={scale} variant={variant}>
      {getAlertIcon(variant, scale, icon)}
      <TextSection $dismissible={dismissible}>
        {React.isValidElement(title) ? title : <Title>{title}</Title>}
        {React.isValidElement(description) ? description : <Description>{description}</Description>}
        <LargeButtonWrapper>
          <Button
            scale="small"
            variant="primary"
            className={variant}
            type="button"
            onClick={onActionButtonClick}
          >
            {action}
          </Button>
          {secondaryAction && (
            <Button
              scale="small"
              className={variant}
              variant="secondary"
              type="button"
              onClick={onSecondaryActionButtonClick}
            >
              {secondaryAction}
            </Button>
          )}
        </LargeButtonWrapper>
      </TextSection>
      {dismissible && (
        <CloseButton
          scale={scale}
          onClick={() => {
            onClose?.()
            setVisible(false)
          }}
        >
          <Icon name="close" size={16} />
        </CloseButton>
      )}
    </StyledBanner>
  )
}

export default Banner
