import React from 'react'
import type { PropsWithChildren } from 'react'

import { BaseLink } from 'components/Link'
import styled from 'styled-components'

export type LogoProps = PropsWithChildren<{
  url: string
  className?: string
}>

function Logo({ url, className, children }: LogoProps): JSX.Element {
  return (
    <BaseLink className={className} href={url}>
      {children}
    </BaseLink>
  )
}

export default styled(Logo)<LogoProps>({})
