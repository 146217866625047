import { useEffect, useState, useCallback, useRef } from 'react'

import type { RefObject } from 'react'

export interface useFocusWithinProps {
  onFocus?: () => void
  onBlur?: () => void
}

/**
 * As CSS's focus-within is not fully supported, this hook
 * helps with identifying focus within elements.
 * @param {Object} props - Hook props
 */
function useFocusWithin<T>(
  props: useFocusWithinProps = {}
): { ref: RefObject<T>; focused: boolean } {
  const { onFocus, onBlur } = props
  const [focused, setFocused] = useState(false)
  const containerRef = useRef<T>(null)

  function getContainer() {
    return (containerRef.current as unknown) as HTMLElement
  }

  const handleFocus = useCallback(
    function handleFocus() {
      onFocus?.()
      setFocused(true)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    function handleBlur() {
      onBlur?.()
      setFocused(false)
    },
    [onBlur]
  )

  useEffect(
    function updateContainerRef() {
      getContainer()?.addEventListener('focusin', handleFocus)
      getContainer()?.addEventListener('focusout', handleBlur)

      return function unsubscribe() {
        getContainer()?.removeEventListener('focusin', handleFocus)
        getContainer()?.removeEventListener('focusout', handleBlur)
      }
    },
    [containerRef, handleBlur, handleFocus]
  )

  return {
    ref: containerRef,
    focused,
  }
}

export default useFocusWithin
