import React from 'react'
import styled, { keyframes } from 'styled-components'

const ANIMATION_DURATION = 0.8

const animation = keyframes`
  0% { opacity: 0 }
  25% { opacity: 1 }
  50% { opacity: 0.66 }
  75% { opacity: 0.33; }
  100% { opacity: 0 }
`

interface LayerProps {
  index: number
}

const Layer = styled.path<LayerProps>`
  animation-name: ${animation};
  animation-duration: ${ANIMATION_DURATION}s;
  animation-delay: -${(props) => props.index * (ANIMATION_DURATION / 4)}s;
  animation-iteration-count: infinite;
`

export interface SpinnerProps {
  title?: string
  size: number
}

export default function Spinner({ title, size, ...props }: SpinnerProps): JSX.Element {
  return (
    <svg
      {...props}
      viewBox="0 0 48 48"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      width={size}
      height={size}
    >
      <title>{title || 'Loading'}</title>
      <g
        id="surface1"
        transform="matrix(0.0806777,0,0,0.0806777,0,-9.96047)"
        fill="#33CC60"
        fillRule="nonzero"
      >
        <Layer
          index={0}
          d="M362.176,638.695C366.445,638.695 370.535,637 373.527,633.996L505.332,502.211L224.305,502.211C222.074,502.211 220.066,501.316 218.617,499.855L84.488,633.996C87.395,636.902 91.406,638.695 95.82,638.695L362.176,638.695Z"
        />
        <Layer
          index={1}
          d="M376.164,482.289L512.648,482.289L512.648,217.34C512.648,212.906 510.855,208.898 507.945,205.988L373.809,340.121C375.266,341.578 376.164,343.59 376.164,345.805L376.164,482.289Z"
        />
        <Layer
          index={2}
          d="M500.48,201.746L235.535,201.746L235.535,338.23L372.02,338.23C374.234,338.23 376.246,339.129 377.703,340.586L511.832,206.445C508.926,203.539 504.918,201.746 500.48,201.746Z"
        />
        <Layer
          index={3}
          d="M215.449,495.488L215.449,214.461L83.668,346.262C80.66,349.289 78.965,353.359 78.965,357.625L78.965,623.973C78.965,628.398 80.758,632.406 83.668,635.313L217.805,501.176C216.348,499.715 215.449,497.707 215.449,495.488Z"
        />
      </g>
    </svg>
  )
}
