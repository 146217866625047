import { useReducer } from 'react'

import DayPicker from './Pickers/DayPicker'
import MonthPicker from './Pickers/MonthPicker'
import YearPicker from './Pickers/YearPicker'

import type {
  PickerMode,
  PickerModeAction,
  usePickerModeProps,
  usePickerModeReturn,
} from './Calendar.types'

function usePickerMode(props?: usePickerModeProps): usePickerModeReturn {
  function reducer(state: PickerMode, action: PickerModeAction) {
    switch (action.type) {
      case 'set-mode':
        return action.payload
      case 'toggle-mode':
        if (state != action.payload) {
          return action.payload
        }

        return 'day-picker'
      default:
        return state
    }
  }

  const [mode, dispatch] = useReducer(reducer, props?.mode || 'day-picker')

  return {
    get() {
      return mode
    },
    is(other: PickerMode) {
      return other != null && other === mode
    },
    dispatch,
    Picker: {
      'day-picker': DayPicker,
      'month-picker': MonthPicker,
      'year-picker': YearPicker,
    }[mode],
  }
}

export default usePickerMode
