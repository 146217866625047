import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/Button'
import { ClearButton, DateInput } from './DatePicker'
import { DateFormatHelper, GenericCalendar } from 'components/Calendar'
import { Dropdown, GenericDropdown } from 'components/Dropdown'
import { getToken as token } from 'theming'
import { Group } from 'components/Layout'
import useDateRangePicker from './useDateRangePicker'

import type { DateRangePickerProps } from './DatePicker.types'

export const formatter = DateFormatHelper('MM/DD/YYYY')

const DropdownMenu = styled(Dropdown.Menu)`
  width: max-content;
  min-width: initial;
  max-height: initial;
  padding: ${token('space-m')};
  overflow-y: initial;
`

const DropdownFooter = styled.div`
  padding-right: ${token('space-m')};
  padding-left: ${token('space-m')};
`

function DateRangePicker(props: DateRangePickerProps): JSX.Element {
  const {
    id,
    name,
    value,
    onChange,
    disabled,
    constraints,
    getRangeStartInputProps,
    getRangeEndInputProps,
    getCalendarProps,
  } = props

  const dateRangePicker = useDateRangePicker({
    constraints,
    disabled,
    id,
    name,
    value,
    onChange,
    getRangeStartInputProps,
    getRangeEndInputProps,
    getCalendarProps,
  })

  function renderRangeEndTrailing() {
    const [currRangeStart, currRangeEnd] = dateRangePicker.value

    if (!disabled && (currRangeStart || currRangeEnd)) {
      return (
        <ClearButton
          size={12}
          type="button"
          aria-label="Clear selection"
          {...dateRangePicker.getClearProps()}
        />
      )
    }

    return null
  }

  function renderDropdownFooter() {
    return (
      <DropdownFooter>
        <Group space="s" justify="flex-end">
          <Button type="button" scale="small" {...dateRangePicker.getClearProps()}>
            Clear
          </Button>
          <Button type="button" scale="small" variant="primary" {...dateRangePicker.getDoneProps()}>
            Done
          </Button>
        </Group>
      </DropdownFooter>
    )
  }

  return (
    <GenericDropdown {...dateRangePicker.getDropdownProps()}>
      <Group space="s">
        <DateInput
          {...dateRangePicker.getRangeStartInputProps()}
          data-testid="input-date-range-start"
        />
        <DateInput
          trailing={renderRangeEndTrailing()}
          {...dateRangePicker.getRangeEndInputProps()}
          data-testid="input-date-range-end"
        />
      </Group>
      <DropdownMenu footer={renderDropdownFooter()}>
        <GenericCalendar {...dateRangePicker.getCalendarProps()} />
      </DropdownMenu>
    </GenericDropdown>
  )
}

export default DateRangePicker
