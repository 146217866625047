import React from 'react'
import type { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { getToken as token } from 'theming'
import { Logo } from './Logo'
import { Menu } from './Menu'
import { MenuDropdownLabel } from './Menu/MenuItemDropdown'
import { OpenSideNavButton } from './OpenSideNavButton'

/* We need to remove the 8px padding-right that DropdownTrigger adds (because of
  BaseStyledButton), in order to keep it always 40px to the right, but only when
  it's the last child. DropdownTigger isn't a StyledComponent so we need to use
  MenuDropdownLabel here since it's the one that wraps DropdownTigger */
const removeRightPadding = css`
  > :last-child ${MenuDropdownLabel} {
    margin-right: -${token('space-m')};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${token('top-navigation-height')};
  padding: 0 ${token('space-2xl')};

  background-color: ${token('top-navigation-background-color')};
  border-bottom: solid 1px ${token('top-navigation-border-color')};

  ${removeRightPadding}
`

export interface TopNavigationProps {
  children: PropsWithChildren<unknown>
}

function TopNavigation({ children }: TopNavigationProps): JSX.Element {
  return <Container>{children}</Container>
}

TopNavigation.Logo = Logo
TopNavigation.Menu = Menu
TopNavigation.OpenSideNavButton = OpenSideNavButton

export default TopNavigation
