import React from 'react'
import type { PropsWithChildren, ReactNode, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import transition from 'styles/transition'
import { Dropdown, DropdownMenu, DropdownMenuItem, DropdownTrigger } from 'components/Dropdown'
import { BaseLink } from 'components/Link'
import useID from 'hooks/useID'
import ellipsizable from 'styles/ellipsizable'
import typography from 'styles/typography'

export const MenuDropdown = styled(Dropdown)({})

export const MenuDropdownSeparator = styled.hr`
  margin: ${token('space-s')} ${token('space-m')};

  color: ${token('top-navigation-dropdown-separator-color')};
`

const MenuDropdownTrigger = styled(DropdownTrigger.Handle)`
  color: ${token('top-navigation-dropdown-trigger-color')};
`

export const MenuDropdownLabel = styled.label`
  ${transition()}

  align-items: center;
  display: flex;

  ${typography('body-bold', { color: 'top-navigation-item-color' })}

  cursor: pointer;

  ${focusable`
    color: ${token('top-navigation-item-color--hover')};
  `}
`

const Ellipsizable = styled.span<{ $max: number }>`
  ${ellipsizable()}

  max-width: ${(props) => props.$max}px;
`

const IconWrapper = styled.div`
  width: ${token('space-m')};
`

const MenuDropdownSubItemLink = styled(BaseLink)`
  align-items: stretch;
  ${typography('body', { color: 'top-navigation-dropdown-subitem-color' })}

  ${hoverable`
    text-decoration: none;
    font-weight: inherit;
  `}

  ${focusable`
    > * {
      background-color: ${token('color-neutral-lighter')}
    }
  `}
`

type MenuDropdownSubItemProps = HTMLAttributes<HTMLElement> & {
  label: string
  url: string
  icon?: ReactNode
  separator?: boolean
}

export function MenuDropdownSubItem({
  icon,
  label,
  url,
  ...rest
}: MenuDropdownSubItemProps): JSX.Element {
  return (
    <MenuDropdownSubItemLink {...rest} href={url}>
      <DropdownMenuItem tabIndex={-1} leading={<IconWrapper>{icon}</IconWrapper>}>
        {label}
      </DropdownMenuItem>
    </MenuDropdownSubItemLink>
  )
}

export type MenuItemDropdownProps = PropsWithChildren<{
  label: string
}>

function MenuItemDropdown(props: MenuItemDropdownProps): JSX.Element {
  const { label, children, ...rest } = props
  const labelId = useID()

  return (
    <MenuDropdown key={label}>
      <MenuDropdownLabel>
        <Ellipsizable $max={120} id={labelId}>
          {label}
        </Ellipsizable>
        <MenuDropdownTrigger {...rest} tabIndex={0} aria-labelledby={labelId} />
      </MenuDropdownLabel>
      <DropdownMenu align="end">{children}</DropdownMenu>
    </MenuDropdown>
  )
}

export { MenuItemDropdown }
