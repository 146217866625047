import React from 'react'
import { createSelectable } from 'hooks/useSelectable'

import type { ToggleGroupProps, ToggleOption } from './ToggleGroup.types'

const ToggleGroupContext = React.createContext<Pick<ToggleGroupProps, 'scale' | 'disabled'>>({
  scale: 'default',
  disabled: false,
})

export const { SelectableContext, useSelectable } = createSelectable<ToggleOption>()

export default ToggleGroupContext
