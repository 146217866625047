import React, { useContext } from 'react'
import styled from 'styled-components'

import { BaseButton } from 'components/Button'
import { getToken as token } from 'theming'
import activatable from 'styles/activatable'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import isEmpty from 'utils/toolset/isEmpty'
import ToggleGroupContext, { SelectableContext } from './ToggleGroupContext'

import type { ToggleGroupOptionProps, ToggleGroupType } from './ToggleGroup.types'

const ToggleButton = styled(BaseButton)<{ $type: ToggleGroupType }>`
  background: ${conditional({
    'color-transparent': whenProps({ 'aria-checked': false }),
    'color-neutral-darkest': whenProps({ 'aria-checked': true }),
  })};

  border-color: ${conditional({
    'color-neutral-darkest': whenProps([
      { $type: 'multiple' },
      { $type: 'single', 'aria-checked': true },
    ]),
    'color-transparent': whenProps({ $type: 'single', 'aria-checked': false }),
  })};

  color: ${conditional({
    'color-neutral-darker': whenProps({ 'aria-checked': false }),
    'color-neutral-white': whenProps({ 'aria-checked': true }),
  })};

  padding: ${token('space-xs')} ${token('space-s')};

  ${hoverable`
    border-color: ${conditional({
      'color-neutral-dark': whenProps({ $type: 'multiple' }),
      'color-transparent': whenProps({ $type: 'single' }),
    })};

    background-color: ${conditional({
      'color-transparent': whenProps({ 'aria-checked': false }),
      'color-neutral-dark': whenProps({ 'aria-checked': true }),
    })};
    color: ${conditional({
      'color-neutral-dark': whenProps({ 'aria-checked': false }),
      'color-neutral-white': whenProps({ 'aria-checked': true }),
    })};
  `}

  ${focusable`
    border-color: ${token('color-neutral-darker')};
    background-color: ${conditional({
      'color-transparent': whenProps({ 'aria-checked': false }),
      'color-neutral-darker': whenProps({ 'aria-checked': true }),
    })};
    color: ${conditional({
      'color-neutral-darker': whenProps({ 'aria-checked': false }),
      'color-neutral-white': whenProps({ 'aria-checked': true }),
    })};

    box-shadow: ${token('button-primary-outline')};
  `}

  ${activatable`
    border-color: ${token('color-neutral')};
    background-color: ${conditional({
      'color-transparent': whenProps({ 'aria-checked': false }),
      'color-neutral': whenProps({ 'aria-checked': true }),
    })};
    color: ${conditional({
      'color-neutral': whenProps({ 'aria-checked': false }),
      'color-neutral-white': whenProps({ 'aria-checked': true }),
    })};
  `}

  ${disableable`
    opacity: 1; // toggle group already applies opacity; we don't want to overlap it.

    border-color: ${conditional({
      'color-neutral-darkest': whenProps([
        { $type: 'multiple' },
        { $type: 'single', 'aria-checked': true },
      ]),
      'color-transparent': whenProps({ $type: 'single', 'aria-checked': false }),
    })};

    color: ${conditional({
      'color-neutral-lightest': whenProps({ 'aria-checked': true }),
      'color-neutral-darker': whenProps({ 'aria-checked': false }),
    })};
  `}
`

function isMultiple(type: ToggleGroupType) {
  return type === 'multiple'
}

function Toggle({ value, children, disabled, ...others }: ToggleGroupOptionProps): JSX.Element {
  const group = useContext(ToggleGroupContext)
  const selectable = useContext(SelectableContext)

  if (isEmpty(selectable)) {
    throw Error('A Toggle options must be rendered inside a ToggleGroup')
  }

  const selected = selectable.selected.has(value)

  return (
    <ToggleButton
      {...others}
      $type={selectable.type()}
      type="button"
      role={isMultiple(selectable.type()) ? 'checkbox' : 'radio'}
      aria-checked={selected}
      disabled={Boolean(disabled || group.disabled)}
      onClick={() => {
        if (isMultiple(selectable.type())) {
          selectable.toggle({ value })
        } else {
          selectable.select({ value })
        }
      }}
      scale={group.scale}
    >
      {children}
    </ToggleButton>
  )
}

export default styled(Toggle)({})
