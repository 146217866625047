import type { StyledComponent } from 'styled-components'

/**
 * Helper that returns the plural form if count is different than 1, otherwise the singular one.
 * @example
 * const StyledParent = styled.div``
 * styledCompounds(StyledParent, { Child: (props) => <div /> })
 * <StyledParent.Child />
 * @param StyledComponent - Parent instance of a styled-component
 * @param compounds - Object with compound components
 * @returns Parent component with type-safe accessable compound components
 */
export function styledCompounds<
  A extends StyledComponent<React.ComponentType, Record<string, unknown>>,
  B extends Record<string, unknown>
>(Parent: A, compounds: B): A & B {
  Object.keys(compounds).forEach((key) => {
    // @ts-expect-error StyledComponent is read-only
    Parent[key] = compounds[key]
  })
  return Parent as A & B
}
