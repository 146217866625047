import { Group } from './Group'
import { Stack } from './Stack'
import { Box } from './Box'
import { Sidebar } from './Sidebar'
import { Switcher } from './Switcher'
import { Grid } from './Grid'

const Layout = (): null => {
  return null
}

Layout.Group = Group
Layout.Stack = Stack
Layout.Box = Box
Layout.Sidebar = Sidebar
Layout.Switcher = Switcher
Layout.Grid = Grid

export default Layout
