import get from 'utils/toolset/get'

import type {
  Selectable,
  SelectableAdapter,
  SelectableKeyType,
  SelectableState,
  SelectableStrategy,
} from './useSelectable.types'

export type SelectableStrategyContext<T extends Selectable> = {
  getAdapter: (type?: string) => SelectableAdapter<T>
}

const GenericAdapter: SelectableAdapter<unknown> = {
  getKey(o): string {
    return get(o, 'value') as string
  },
}

function createSelectionStrategy<T extends Selectable>(props: {
  multiple?: boolean
  adapters: Record<string, SelectableAdapter<T>>
}): SelectableStrategy<T> {
  const { adapters, multiple } = props

  const context: SelectableStrategyContext<T> = {
    getAdapter(type?: string) {
      if (type == null) {
        return GenericAdapter
      }

      return adapters[type] || GenericAdapter
    },
  }

  if (multiple) {
    return MultipleSelectionStrategy(context)
  }

  return SingleSelectionStrategy(context)
}

export function SingleSelectionStrategy<T extends Selectable>(
  context: SelectableStrategyContext<T>
): SelectableStrategy<T> {
  return {
    type() {
      return 'single'
    },
    init(items: T[]) {
      return this.select(items, new Map())
    },
    select(items: T[]) {
      const newSelection = new Map<SelectableKeyType, T>()

      for (let i = 0; i < items.slice(0, 1).length; i++) {
        const adapter = context.getAdapter(items[i]._type)
        newSelection.set(adapter.getKey(items[i]), items[i])
      }

      return newSelection
    },
    unselect(keys: SelectableKeyType[], selection: SelectableState<T>) {
      const newSelection = new Map<SelectableKeyType, T>(selection)

      for (let i = 0; i < keys.length; i++) {
        newSelection.delete(keys[i])
      }

      return newSelection
    },
    toggle(items: T[], selection: SelectableState<T>) {
      const newSelection = new Map<SelectableKeyType, T>()

      for (let i = 0; i < items.slice(0, 1).length; i++) {
        const adapter = context.getAdapter(items[i]._type)

        if (!selection.has(adapter.getKey(items[i]))) {
          newSelection.set(adapter.getKey(items[i]), items[i])
        }
      }

      return newSelection
    },
    clear() {
      return new Map<SelectableKeyType, T>()
    },
  }
}

export function MultipleSelectionStrategy<T extends Selectable>(
  context: SelectableStrategyContext<T>
): SelectableStrategy<T> {
  return {
    type() {
      return 'multiple'
    },
    init(items: T[]) {
      return this.select(items, new Map<SelectableKeyType, T>())
    },
    select(items: T[], selection: SelectableState<T>) {
      const newSelection = new Map<SelectableKeyType, T>(selection)

      for (let i = 0; i < items.length; i++) {
        const adapter = context.getAdapter(items[i]._type)
        newSelection.set(adapter.getKey(items[i]), items[i])
      }

      return newSelection
    },
    unselect(keys: SelectableKeyType[], selection: SelectableState<T>) {
      const newSelection = new Map<SelectableKeyType, T>(selection)

      for (let i = 0; i < keys.length; i++) {
        newSelection.delete(keys[i])
      }

      return newSelection
    },
    toggle(items: T[], selection: SelectableState<T>) {
      const newSelection = new Map<SelectableKeyType, T>(selection)

      for (let i = 0; i < items.length; i++) {
        const adapter = context.getAdapter(items[i]._type)
        const key = adapter.getKey(items[i])

        if (!selection.has(key)) {
          newSelection.set(key, items[i])
        } else {
          newSelection.delete(key)
        }
      }

      return newSelection
    },
    clear() {
      return new Map<SelectableKeyType, T>()
    },
  }
}

export default createSelectionStrategy
