/**
 * Add basic CSS style for hide elements
 * @param {boolean} accessible - should keep element visible for screen readers
 * @returns {string}
 */
function hiddenStyle(accessible = false): string {
  if (accessible) {
    return `
      border: 0 !important;
      clip: rect(0 0 0 0) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      white-space: nowrap !important;
      width: 1px !important;
      pointer-events: none;
    `
  }

  return `
    display: none !important;
    opacity: 0 !important;
  `
}

export default hiddenStyle
