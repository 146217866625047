import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { Icon } from 'components/Icon'

export type OpenSideNavButtonProps = {
  className?: string
  onClick: () => void
}

const Container = styled.button`
  margin-right: ${token('space-2xl')};

  background: none;
  border: none;
  cursor: pointer;
`

function OpenSideNavButton({ className, onClick }: OpenSideNavButtonProps): JSX.Element {
  return (
    <Container className={className} onClick={onClick}>
      <Icon name="burguer-menu" size={24} data-testid="toggle-sidenav" />
    </Container>
  )
}

export default styled(OpenSideNavButton)<OpenSideNavButtonProps>({})
