import { useEffect, useRef } from 'react'

import { formatDate, getInitialCalendarSetup, getInputValue, setInputValue } from './useDatePicker'
import { getSelection } from '../Calendar/Calendar.helpers'
import { useCalendar } from 'components/Calendar'
import { useDropdown } from 'components/Dropdown'

import type { CalendarDate } from 'components/Calendar'
import type { DateRangePickerProps } from './DatePicker.types'

const AT_RANGE_START = 0
const AT_RANGE_END = 1

function useDateRangePicker(props: DateRangePickerProps) {
  const { constraints, disabled, id, name, onChange, value, ...rest } = props

  const dropdown = useDropdown({ disabled })
  const calendar = useCalendar({
    mode: 'range',
    constraints,
    onSelect: handleDateSelect,
  })

  const inputsRef = useRef<[HTMLInputElement | null, HTMLInputElement | null]>([null, null])
  const currentlyPickingRef = useRef<'range-start' | 'range-end' | 'none'>('none')

  function handleDateSelect(selected: [string | null, string | null]) {
    ;[AT_RANGE_START, AT_RANGE_END].forEach((index) => {
      const input = inputsRef.current[index]
      const formattedDate = formatDate(selected[index])

      /**
       * This check ensures we will keep the valid date the user typed as-is and, since we're using
       * an uncontrolled input, ensures that the formatting that happens in `formatDate`
       * will not interfere with cursor position when we set the input value.
       * Bottom line is: if the input already contains the select date, no change is necessary.
       */
      if (formattedDate != formatDate(getInputValue(input))) {
        setInputValue(input, formattedDate)
      }
    })

    onChange?.({ target: { id, name, value: selected } })
  }

  function getDateInputChangeHandler(index: number) {
    return function handleDateInputChange(date: CalendarDate) {
      if (date != null) {
        const timestamp = date.getTime()

        const newSelected: typeof calendar.selected = [...calendar.selected]
        newSelected[index] = timestamp

        calendar.select(newSelected)
      } else {
        calendar.clear()
      }
    }
  }

  function handleDateInputClick() {
    if (!dropdown.expanded) {
      calendar.set(getInitialCalendarSetup(calendar.selected))
      dropdown.toggle()
    }
  }

  function handleDayClick(timestamp: number) {
    const [rangeStart, rangeEnd] = calendar.selected

    if (currentlyPickingRef.current == 'range-start') {
      if (rangeEnd != null) {
        calendar.select([timestamp, rangeEnd].sort() as [number, number])
        inputsRef.current[AT_RANGE_START]?.focus()
      } else {
        calendar.select([timestamp, rangeEnd])
        inputsRef.current[AT_RANGE_END]?.focus()
      }
    } else if (currentlyPickingRef.current == 'range-end') {
      if (rangeStart != null) {
        calendar.select([rangeStart, timestamp].sort() as [number, number])
      } else {
        calendar.select([rangeStart, timestamp])
      }

      inputsRef.current[AT_RANGE_END]?.focus()
    }
  }

  function handleClearClick() {
    calendar.clear()
    inputsRef.current[AT_RANGE_START]?.focus()
  }

  function handleDropdownBlur() {
    currentlyPickingRef.current = 'none'

    if ([AT_RANGE_START, AT_RANGE_END].some((index) => !inputsRef.current[index])) {
      return
    }

    if ([AT_RANGE_START, AT_RANGE_END].every((index) => !getInputValue(inputsRef.current[index]))) {
      calendar.clear()
    } else {
      const selected = getSelection(calendar.selected).map(formatDate)

      ;[AT_RANGE_START, AT_RANGE_END].forEach((index) => {
        setInputValue(inputsRef.current[index], selected[index])
      })
    }
  }

  function handleDoneClick() {
    handleDropdownBlur()
    dropdown.collapse()
  }

  function getDropdownProps() {
    return {
      disabled,
      toggle: dropdown.toggle,
      expanded: dropdown.expanded,
      onBlur: handleDropdownBlur,
    }
  }

  function getCalendarProps() {
    return {
      ...rest.getCalendarProps?.(),
      calendar,
      onDayClick: handleDayClick,
    }
  }

  function getRangeStartInputProps() {
    return {
      ...rest.getRangeStartInputProps?.(),
      id: id != null ? `${id}-start` : id,
      name: name != null ? `${name}-start` : name,
      disabled,
      onChange: getDateInputChangeHandler(AT_RANGE_START),
      onClick: handleDateInputClick,
      onFocus() {
        currentlyPickingRef.current = 'range-start'
      },
      ref(node: HTMLInputElement | null) {
        if (node != null) {
          inputsRef.current[AT_RANGE_START] = node
        }
      },
    }
  }

  function getRangeEndInputProps() {
    return {
      ...rest.getRangeEndInputProps?.(),
      id: id != null ? `${id}-end` : id,
      name: name != null ? `${name}-end` : name,
      disabled,
      onChange: getDateInputChangeHandler(AT_RANGE_END),
      onClick: handleDateInputClick,
      onFocus() {
        currentlyPickingRef.current = 'range-end'
      },
      ref(node: HTMLInputElement | null) {
        if (node != null) {
          inputsRef.current[AT_RANGE_END] = node
        }
      },
    }
  }

  function getClearProps() {
    return {
      onClick: handleClearClick,
    }
  }

  function getDoneProps() {
    return {
      onClick: handleDoneClick,
    }
  }

  useEffect(
    function onValuePropChange() {
      if ([AT_RANGE_START, AT_RANGE_END].some((index) => !inputsRef.current[index])) {
        return
      }

      const newSelected = getSelection(value)
      const currSelected = calendar.selected

      const hasChanged = [AT_RANGE_START, AT_RANGE_END].some((index) => {
        return newSelected[index] != currSelected[index]
      })

      if (hasChanged) {
        ;[AT_RANGE_START, AT_RANGE_END].forEach((index) => {
          setInputValue(inputsRef.current[index], formatDate(newSelected[index]))
        })

        calendar.select(newSelected)
        calendar.set(getInitialCalendarSetup(newSelected))
      }
    },
    // we just intend to update the selection when the prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  return {
    value: getSelection(calendar.selected).map(formatDate),

    getDropdownProps,
    getRangeStartInputProps,
    getRangeEndInputProps,
    getClearProps,
    getCalendarProps,
    getDoneProps,
  }
}

export default useDateRangePicker
