import { useEffect, useRef } from 'react'

import { useDropdown } from 'components/Dropdown'
import { TODAY, DateFormatHelper, DateHelper, useCalendar } from 'components/Calendar'

import { getSelection } from '../Calendar/Calendar.helpers'

import type { CalendarDate } from 'components/Calendar'
import type { DatePickerProps } from './DatePicker.types'

export const formatter = DateFormatHelper('MM/DD/YYYY')

export function formatDate(date?: number | string | null): string {
  if (!date) {
    return ''
  }

  return formatter.format(DateHelper(date))
}

export function getInitialCalendarSetup(
  selection: number | string | null | [number | string | null, number | string | null]
) {
  const [selectionStart] = getSelection(selection)

  if (!selectionStart) {
    return {
      month: TODAY.getMonth(),
      year: TODAY.getYear(),
    }
  }

  const startDate = DateHelper(selectionStart)

  return {
    month: startDate.getMonth(),
    year: startDate.getYear(),
  }
}

export function setInputValue(input: HTMLInputElement | null, value: string) {
  if (input != null) {
    input.value = value
  }
}

export function getInputValue(input?: HTMLInputElement | null) {
  if (input != null) {
    return input.value
  }

  return null
}

function useDatePicker(props: DatePickerProps) {
  const { constraints, disabled, id, name, onChange, value, ...rest } = props

  const dropdown = useDropdown({ disabled })
  const calendar = useCalendar({
    mode: 'single',
    constraints,
    onSelect: handleDateSelect,
  })

  const inputRef = useRef<HTMLInputElement>()

  function handleDateSelect(selection: [string | null, string | null]) {
    if (!inputRef.current) {
      return
    }

    const [formattedDate] = getSelection(selection).map(formatDate)

    /**
     * This check ensures we will keep the valid date the user typed as-is and, since we're using
     * an uncontrolled input, ensures that the formatting that happens in `getFormattedSelection`
     * will not interfere with cursor position when we set the input value.
     * Bottom line is: if the input already contains the select date, no change is necessary.
     */
    if (formattedDate != formatDate(getInputValue(inputRef.current))) {
      setInputValue(inputRef.current, formattedDate)
    }

    onChange?.({ target: { id, name, value: selection[0] } })
  }

  function handleDateInputChange(date: CalendarDate) {
    if (date != null) {
      const timestamp = date.getTime()

      calendar.select([timestamp, timestamp])
    } else {
      calendar.clear()
    }
  }

  function handleDateInputClick() {
    if (!dropdown.expanded) {
      calendar.set(getInitialCalendarSetup(calendar.selected))
      dropdown.toggle()
    }
  }

  function handleDayClick(timestamp: number) {
    calendar.select([timestamp, timestamp])
    dropdown.collapse()
  }

  function handleClearClick() {
    calendar.clear()
    inputRef.current?.focus()
  }

  function getDropdownProps() {
    return {
      disabled,
      toggle: dropdown.toggle,
      expanded: dropdown.expanded,
      onBlur() {
        if (!inputRef.current) {
          return
        }

        const [currSelectedDate] = getSelection(calendar.selected).map(formatDate)
        const currInputValue = getInputValue(inputRef.current)

        if (currInputValue == currSelectedDate) {
          return
        }

        if (!currInputValue) {
          calendar.clear()
        } else {
          setInputValue(inputRef.current, currSelectedDate)
        }
      },
    }
  }

  function getCalendarProps() {
    return {
      ...rest.getCalendarProps?.(),
      calendar,
      onDayClick: handleDayClick,
    }
  }

  function getInputProps() {
    return {
      ...rest.getInputProps?.(),
      id,
      name,
      disabled,
      onChange: handleDateInputChange,
      onClick: handleDateInputClick,
      ref(node: HTMLInputElement | null) {
        if (node != null) {
          inputRef.current = node
        }
      },
    }
  }

  function getClearProps() {
    return {
      onClick: handleClearClick,
    }
  }

  useEffect(
    function onValuePropChange() {
      if (!inputRef.current) {
        return
      }

      const [newSelection] = getSelection(value)
      const [currSelection] = calendar.selected

      if (newSelection != currSelection) {
        setInputValue(inputRef.current, formatDate(newSelection))

        calendar.select([newSelection, newSelection])
        calendar.set(getInitialCalendarSetup([newSelection, newSelection]))
      }
    },
    // we just intend to update the selection when the prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  return {
    value: getSelection(calendar.selected).map(formatDate),

    getDropdownProps,
    getInputProps,
    getClearProps,
    getCalendarProps,
  }
}

export default useDatePicker
