import { createContext } from 'react'
import { createSelectable } from 'hooks/useSelectable'

import type { Option, useSelectReturn } from './Select.types'

const { SelectableContext, useSelectable } = createSelectable<Option>()

export { SelectableContext, useSelectable }

const SelectContext = createContext<useSelectReturn | null>(null)
export { SelectContext }
