import { isObject } from '@loadsmart/utils-object'
import { isFunction } from '@loadsmart/utils-function'
import type { F } from 'ts-toolbelt'

import { getToken } from 'theming'
import type { ThemeToken, ThemedProps } from 'theming'
import get from 'utils/toolset/get'
import toArray from 'utils/toolset/toArray'

type WhenProps<K> = K | undefined | ((value: K) => boolean | undefined)

export type When<P> = {
  [Key in keyof P]?: WhenProps<P[Key]> | WhenProps<P[Key]>[] | undefined
}

/**
 * Utility to generate style/class name conditions based on a components props.
 * Expected prop values can be a single value, an array of values or a function/callback.
 * @example
 * ```jsx
 * whenProps({
 *  'prop-a': true, // checks `props['prop-a']` === true`
 *  'prop-b': [1, 2], // checks `toArray([1, 2]).includes(props['prop-b'])`
 *  'prop-c': (value) => value + 1 // checks `Boolean(callback(props['prop-c']))`
 *  'prop-d': Boolean // checks `Boolean(Boolean(props['prop-d']))`
 * })
 * ```
 * @param {...Object} conditions
 * @returns {(props: Object}) => boolean} Returns function that consumes component props.
 */
export function whenProps<P>(conditions: When<F.Narrow<P>> | When<F.Narrow<P>>[]) {
  return function (props: P): boolean {
    const safeConditions = toArray(conditions)

    let res = false

    for (let i = 0; i < safeConditions.length; i++) {
      const condition = safeConditions[i]
      const keys = Object.keys(condition)

      let temp = true

      for (let j = 0; j < keys.length && temp; j++) {
        const key = keys[j]
        const propValue = get(props, key) as P[keyof P]
        const conditionValue = condition[key as keyof typeof condition]

        if (Array.isArray(conditionValue)) {
          temp = temp && toArray(conditionValue).includes(propValue)
        } else if (isFunction(conditionValue)) {
          temp = temp && Boolean(conditionValue(propValue))
        } else {
          temp = temp && (conditionValue as unknown) === propValue
        }
      }

      res = res || temp
    }

    return res
  }
}

type ConditionObject<P> = Record<
  string,
  string | number | boolean | ((props: P) => boolean) | undefined
>

function handleConditionObject<P>(condition: ConditionObject<P>, props: P): string {
  const keys = Object.keys(condition || {})

  const res = keys.reduce((acc, key) => {
    let value = condition[key]

    if (isFunction(value)) {
      value = value(props)
    }

    if (value) {
      const tokenKey = key as ThemeToken
      const result = (getToken(tokenKey, (props as unknown) as ThemedProps) ?? key) as string
      return [...acc, result]
    }

    return acc
  }, [] as string[])

  return res.join(' ')
}

type Condition<P> = number | string | ConditionObject<P> | ((props: P) => string)

/**
 * Concatenate style properties or class names conditionally.
 * Conditions can be functions that consume components props,
 * objects, strings, or numbers (that will be coerced to strings).
 * @example
 * ```jsx
 * conditional(1, 'some-class', {
 *  'class-a': true,
 *  'class-b': (props) => props.showClassB,
 * }, (props) => props.className)
 * ```
 * @param conditions
 * @returns {(props: ThemedProps) => string} Returns function that consumes component props.
 */
function conditional<P>(...conditions: Condition<P>[]) {
  return function (props: P): string {
    let classes: string[] = []

    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]

      if (isFunction(condition)) {
        classes.push(condition(props))
      } else if (isObject(condition)) {
        classes = classes.concat(handleConditionObject<P>(condition, props))
      } else if (condition) {
        classes.push(String(condition))
      }
    }

    return classes.join(' ')
  }
}

export default conditional
