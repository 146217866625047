import { useCallback, useEffect, useState } from 'react'

export interface useDialogProps {
  open: boolean
  onOpen?: () => void
  onClose?: () => void
}

function useDialog({
  open: openProp,
  onOpen,
  onClose,
}: useDialogProps): {
  open: boolean
  show: () => void
  hide: () => void
  toggle: () => void
} {
  const [open, setOpen] = useState(openProp)

  const show = useCallback(
    function show() {
      setOpen(true)
      onOpen?.()
    },
    [onOpen]
  )

  const hide = useCallback(
    function hide() {
      setOpen(false)
      onClose?.()
    },
    [onClose]
  )

  const toggle = useCallback(
    function toggle() {
      if (open) {
        hide()
      } else {
        show()
      }
    },
    [hide, open, show]
  )

  useEffect(
    function updateState() {
      setOpen(openProp)
    },
    [openProp]
  )

  return {
    open,
    show,
    hide,
    toggle,
  }
}

export default useDialog
