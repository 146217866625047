import React, { useContext, forwardRef } from 'react'
import styled from 'styled-components'

import { DropdownContext, DropdownTrigger, GenericDropdownTrigger } from 'components/Dropdown'
import { getToken as token } from 'theming'
import { TextField, Trailing } from 'components/TextField'
import focusable from 'styles/focusable'

import type { SelectTriggerProps } from './Select.types'

const GenericSelectTrigger = styled(GenericDropdownTrigger)`
  background: ${token('color-neutral-white')};
`

const SelectTriggerSearchField = styled(TextField)`
  ${focusable`
    box-shadow: none;
  `}

  background-color: transparent;

  border: none;
  border-radius: ${token('border-radius-s')} 0 0 ${token('border-radius-s')};

  height: 100%;

  flex: 1;

  ${Trailing} {
    margin: 0 ${token('space-xs')} 0 0;
  }
`

const Separator = styled.span`
  height: 100%;

  margin: 0;

  border-right-color: ${token('color-neutral-light')};
  border-right-width: 1px;
  border-right-style: solid;
`

const SelectTrigger = forwardRef<HTMLInputElement, SelectTriggerProps>(function SelectTrigger(
  props: SelectTriggerProps,
  ref
) {
  const { className, ...others } = props
  const { disabled, expanded, toggle } = useContext(DropdownContext)

  function handleClick() {
    if (!expanded) {
      toggle()
    }
  }

  return (
    <GenericSelectTrigger className={className}>
      <SelectTriggerSearchField
        data-testid="select-trigger-search-field"
        {...others}
        ref={ref}
        type="search"
        disabled={disabled}
        onClick={handleClick}
      />
      <Separator />
      <DropdownTrigger.Handle data-testid="select-trigger-handle" />
    </GenericSelectTrigger>
  )
})

export default SelectTrigger
