import { useState, useRef } from 'react'

import getID from 'utils/toolset/getID'
import isEmpty from 'utils/toolset/isEmpty'

type FingerprintAdapter<T> = (item: T) => string

/**
 * This hook helps with stabilizing changes for components that rely on non-primitive props, more specifically, arrays,
 * objects, maps, sets and so on; when we need to update an internal state based on such types of prop, we might run into an infinite loop.
 * By creating a fingerprint - a string that identifies that set of items - we can garantee that the same set of items will
 * always return the same fingerprint, thus, making check for changes more predictable.
 *
 * @param adapter - adapter function that gets a unique identifier for each item in the `items` prop
 * @param items - list of items whose fingerprint should be checked.
 * @returns
 */
function useFingerprint<T>(
  adapter: FingerprintAdapter<T>,
  items: T[]
): {
  fingerprint: string
  getFingerprint: (items?: T[] | null, insert?: boolean) => string
  resetFingerprint: (items: T[]) => void
  hasSameFingerprint: (otherItems: T[]) => boolean
} {
  const knownRef = useRef({} as Record<string, T | string>)
  const [fingerprint, setFingerprint] = useState(() => getFingerprint('', items, true))

  /**
   * Get a fingerprint based on the sorted items identifiers.
   *
   * @param {string[]} items - array of items
   * @param {boolean} update - update `known` with the provided `items`, if it contains different set of items.
   * @returns {string}
   */
  function getFingerprint(currentFingerprint: string, items?: T[] | null, update = false) {
    const known = knownRef.current

    const safeItems = items || []

    let newKnown: Record<string, T | string> = {}
    let hasSameItems = Object.keys(known).length === safeItems.length

    for (let i = 0; i < safeItems.length; i++) {
      const item = safeItems[i]
      const key = adapter(item)

      hasSameItems = hasSameItems && known[key] != null

      newKnown = {
        ...newKnown,
        // we associate a previously created or a new random value to compose the fingerprint
        [key]: known[key] ?? getID(),
      }
    }

    if (hasSameItems) {
      return currentFingerprint
    }

    if (update) {
      knownRef.current = newKnown
    }

    const fingerprint = Object.keys(newKnown)
      .sort()
      .reduce((fingerprint, item) => {
        return `${fingerprint}${newKnown[item] as string}`
      }, '')

    return fingerprint
  }

  return {
    fingerprint,
    getFingerprint(items?: T[] | null, update = false) {
      return getFingerprint(fingerprint, items, update)
    },
    resetFingerprint(items: T[]) {
      setFingerprint(getFingerprint(fingerprint, items, true))
    },
    /**
     * Check if the provided items have the same fingerprint.
     * @param {string[]} otherItems - items
     * @returns {boolean}
     */
    hasSameFingerprint(otherItems: T[]) {
      return fingerprint === getFingerprint(fingerprint, otherItems)
    },
  }
}

export default useFingerprint
