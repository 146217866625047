import React, { ChangeEvent, InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

import { Icon } from 'components/Icon'
import conditional, { whenProps } from 'tools/conditional'
import disableable from 'styles/disableable'
import focusable from 'styles/focusable'
import font from 'styles/font'
import hidden from 'styles/hidden'
import hoverable from 'styles/hoverable'
import { getToken as token } from 'theming'

import transition from 'styles/transition'

interface WithAdditionalProps {
  active?: boolean
  onToggle?: (event: ChangeEvent<HTMLInputElement>) => void
  scale?: 'default' | 'large'
}

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement>, WithAdditionalProps {
  className?: string
}

const SwitchWrapper = styled.label<WithAdditionalProps>`
  ${transition()}

  ${font({})};

  user-select: none;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  position: relative;

  display: inline-flex;

  width: ${conditional({
    'switch-width': whenProps({ scale: 'default' }),
    'switch-large-width': whenProps({ scale: 'large' }),
  })};
  height: ${conditional({
    'switch-height': whenProps({ scale: 'default' }),
    'switch-large-height': whenProps({ scale: 'large' }),
  })};

  background-color: ${conditional({
    'switch-inactive-background-color': whenProps({ active: false }),
    'switch-active-background-color': whenProps({ active: true }),
  })};

  border-radius: ${conditional({
    'switch-border-radius': whenProps({ scale: 'default' }),
    'switch-large-border-radius': whenProps({ scale: 'large' }),
  })};

  ${hoverable`
    background: ${conditional({
      'switch-inactive-background-color--hover': whenProps({ active: false }),
      'switch-active-background-color--hover': whenProps({ active: true }),
    })};
  `}

  ${focusable`
    box-shadow: ${token('switch-outline')}
  `}

  ${disableable``}
`

const Slider = styled.span<WithAdditionalProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  :before {
    position: absolute;
    bottom: ${conditional({
      'switch-slider-y': whenProps({ scale: 'default', active: false }),
      'switch-slider-active-y': whenProps({ scale: 'default', active: true }),
      'switch-slider-large-y': whenProps({ scale: 'large', active: false }),
      'switch-slider-large-active-y': whenProps({ scale: 'large', active: true }),
    })};
    left: ${conditional({
      'switch-slider-x': whenProps({ scale: 'default', active: false }),
      'switch-slider-active-x': whenProps({ scale: 'default', active: true }),
      'switch-slider-large-x': whenProps({ scale: 'large', active: false }),
      'switch-slider-large-active-x': whenProps({ scale: 'large', active: true }),
    })};

    width: ${conditional({
      'switch-slider-size': whenProps({ scale: 'default' }),
      'switch-slider-large-size': whenProps({ scale: 'large' }),
    })};

    height: ${conditional({
      'switch-slider-size': whenProps({ scale: 'default' }),
      'switch-slider-large-size': whenProps({ scale: 'large' }),
    })};

    background-color: ${token('switch-slider-background-color')};
    border-radius: ${token('switch-slider-border-radius')};

    ${transition({
      timingFunction: 'ease-out',
      duration: '150ms',
    })}

    content: '';
  }
`

const HiddenInput = styled.input`
  ${hidden(true)}
`

const ActiveIcon = styled(Icon)<WithAdditionalProps>`
  position: absolute;
  top: ${conditional({
    'switch-icon-active-y': whenProps({ scale: 'default' }),
    'switch-large-icon-active-y': whenProps({ scale: 'large' }),
  })};
  left: ${conditional({
    'switch-icon-active-x': whenProps({ scale: 'default' }),
    'switch-large-icon-active-x': whenProps({ scale: 'large' }),
  })};

  width: ${conditional({
    'switch-icon-width': whenProps({ scale: 'default' }),
    'switch-large-icon-width': whenProps({ scale: 'large' }),
  })};

  height: ${conditional({
    'switch-icon-height': whenProps({ scale: 'default' }),
    'switch-large-icon-height': whenProps({ scale: 'large' }),
  })};

  color: ${token('switch-icon-color')};
`

const InactiveIcon = styled(Icon)<WithAdditionalProps>`
  position: absolute;
  top: ${conditional({
    'switch-icon-inactive-y': whenProps({ scale: 'default' }),
    'switch-large-icon-inactive-y': whenProps({ scale: 'large' }),
  })};
  left: ${conditional({
    'switch-icon-inactive-x': whenProps({ scale: 'default' }),
    'switch-large-icon-inactive-x': whenProps({ scale: 'large' }),
  })};

  width: ${conditional({
    'switch-icon-width': whenProps({ scale: 'default' }),
    'switch-large-icon-width': whenProps({ scale: 'large' }),
  })};

  height: ${conditional({
    'switch-icon-height': whenProps({ scale: 'default' }),
    'switch-large-icon-height': whenProps({ scale: 'large' }),
  })};

  color: ${token('switch-icon-color')};
`

function Switch({
  active = false,
  className,
  disabled = false,
  name,
  onToggle,
  scale = 'default',
  ...others
}: SwitchProps): JSX.Element {
  return (
    <SwitchWrapper
      htmlFor={name}
      title={active ? 'active' : 'inactive'}
      active={active}
      className={clsx(className, {
        'is-disabled': disabled,
      })}
      scale={scale}
    >
      {active ? (
        <ActiveIcon name="check" scale={scale} data-testid="switch-icon-active" />
      ) : (
        <InactiveIcon name="close" scale={scale} data-testid="switch-icon-inactive" />
      )}
      <Slider active={active} scale={scale} />
      <HiddenInput
        type="checkbox"
        {...others}
        id={name}
        name={name}
        checked={active}
        disabled={disabled}
        onChange={onToggle}
      />
    </SwitchWrapper>
  )
}

export default Switch
