import React, { useContext, forwardRef } from 'react'
import styled from 'styled-components'

import { DateFormatHelper, DateHelper, GenericCalendar } from 'components/Calendar'
import { Dropdown, DropdownContext, GenericDropdown } from 'components/Dropdown'
import { getToken as token } from 'theming'
import { Group } from 'components/Layout'
import { Icon } from 'components/Icon'
import { TextField as DefaultTextField } from 'components/TextField'
import CloseButton from 'common/CloseButton'
import useDatePicker from './useDatePicker'

import type { CalendarDate } from 'components/Calendar'
import type { ChangeEvent } from 'react'
import type { DatePickerProps } from './DatePicker.types'
import type { TextFieldProps } from 'components/TextField'

/**
 * https://www.regular-expressions.info/dates.html
 * https://learning.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s04.html#validation-dates-problem
 */
const DATE_REGEX = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(19|20)\d{2}$/

export const formatter = DateFormatHelper('MM/DD/YYYY')

const TextField = styled(DefaultTextField)`
  & > input {
    width: 10ch;
  }
`

const DropdownMenu = styled(Dropdown.Menu)`
  width: max-content;
  min-width: initial;
  max-height: initial;
  padding: ${token('space-m')};
  overflow-y: initial;
`

export const ClearButton = styled(CloseButton)`
  width: 1em;
  height: 1em;

  margin-left: calc(-1 * ${token('space-s')}); // compensate for input's padding-left
`

interface DateInputProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (date: CalendarDate) => void
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(function DateInput(
  props: DateInputProps,
  ref
): JSX.Element {
  const { onChange, ...rest } = props
  const dropdown = useContext(DropdownContext)

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event

    if (!DATE_REGEX.test(value)) {
      return
    }

    onChange(DateHelper(value))
  }

  // TODO: use type="date" when IE is no longer in the way
  return (
    <TextField
      placeholder="00/00/0000"
      {...rest}
      ref={ref}
      type="text"
      disabled={dropdown.disabled}
      leading={<Icon name="calendar" />}
      onChange={handleInputChange}
    />
  )
})

function DatePicker(props: DatePickerProps): JSX.Element {
  const {
    id,
    name,
    value,
    onChange,
    disabled,
    constraints,
    getInputProps,
    getCalendarProps,
  } = props

  const datePicker = useDatePicker({
    constraints,
    disabled,
    id,
    name,
    value,
    onChange,
    getInputProps,
    getCalendarProps,
  })

  function renderTrailing() {
    const [currSelectedDate] = datePicker.value

    if (!disabled && currSelectedDate) {
      return (
        <ClearButton
          size={12}
          type="button"
          aria-label="Clear selection"
          {...datePicker.getClearProps()}
        />
      )
    }

    return null
  }

  return (
    <GenericDropdown {...datePicker.getDropdownProps()}>
      <Group space="s">
        <DateInput trailing={renderTrailing()} {...datePicker.getInputProps()} />
      </Group>
      <DropdownMenu>
        <GenericCalendar {...datePicker.getCalendarProps()} />
      </DropdownMenu>
    </GenericDropdown>
  )
}

export default DatePicker
