import { isNil } from '@loadsmart/utils-object'

// TODO: add this helper to @loadsmart/utils
function toArray<T>(value: T | T[]): T[] {
  if (isNil(value)) {
    return []
  }

  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

export default toArray
