import React, { Fragment, useRef } from 'react'
import type { PropsWithChildren, HTMLAttributes } from 'react'
import styled from 'styled-components'
import type { CSSProperties } from 'styled-components'

import { Icon } from 'components/Icon'
import { conditional, whenProps, prop } from 'tools/index'
import { getToken as token } from 'theming'
import transition from 'styles/transition'
import { useHeightExpansionToggler } from 'hooks/useHeightExpansionToggler'

import MenuLink from './MenuLink'
import MenuBaseItem from './MenuBaseItem'

interface IExpandable {
  $expanded?: boolean
  $height?: CSSProperties['height']
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${token('space-s')};
`

const StyledIcon = styled(Icon)<IExpandable>`
  color: ${token('color-neutral')};

  transform: rotate(
    ${conditional({
      '0deg': whenProps({ $expanded: true }),
      '-90deg': whenProps({ $expanded: false }),
    })}
  );
`

const ExpandableItems = styled.div<IExpandable>`
  overflow: hidden;
  ${transition({ property: 'height' })}

  height: ${prop('$height', 0)}px;

  ${MenuLink} {
    padding-left: ${token('space-xl')};
  }
`

const ItemsWrapper = styled.div<IExpandable>`
  ${transition()}

  opacity: ${conditional({
    0: whenProps({ $expanded: false }),
    1: whenProps({ $expanded: true }),
  })};

  transform: translateY(
    ${conditional({
      '-10%': whenProps({ $expanded: false }),
      '0%': whenProps({ $expanded: true }),
    })}
  );
`

export type MenuExpandableProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    label: string
  }>

function MenuExpandable({ label, children, ...props }: MenuExpandableProps): JSX.Element {
  const expandableItemsRef = useRef<HTMLDivElement>(null)
  const { expanded, toggle, height } = useHeightExpansionToggler(expandableItemsRef)

  return (
    <div {...props} role="none">
      <MenuBaseItem
        onClick={toggle}
        label={label}
        trailing={
          <IconWrapper>
            <StyledIcon $expanded={expanded} name="chevron-down" size={16} />
          </IconWrapper>
        }
        aria-haspopup="true"
        aria-expanded={expanded}
      />
      <ExpandableItems $height={height} role="menu">
        <ItemsWrapper $expanded={expanded} ref={expandableItemsRef} role="presentation">
          {React.Children.map(children, (child) => {
            if (!child) return Fragment

            if (typeof child === 'object' && 'type' in child) {
              return React.cloneElement(child, {
                key: label,
                tabIndex: expanded ? 0 : -1,
                ariaHidden: !expanded,
              })
            }

            return child
          })}
        </ItemsWrapper>
      </ExpandableItems>
    </div>
  )
}

export default MenuExpandable
