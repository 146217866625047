/**
 * Helper that returns the plural form if count is different than 1, otherwise the singular one.
 * @example
 * let total = 3
 * `Item${pluralize(total)}` // "Items"
 * `Child${pluralize(total, 'ren')}` // "Children"
 * `Pe${pluralize(total, 'ople', 'rson')}` // "People"
 * `${pluralize(total, 'People', 'Person')}` // "People"
 * @param count - total of items
 * @param plural - defaults to "s"
 * @param singular - defaults to ""
 * @returns plural or singular.
 */
export default function pluralize(count: number, plural = 's', singular = ''): string {
  return count !== 1 ? plural : singular
}
