/**
 * This is an async wrapper for waiting a promise to be settled without the hussle of handling error.
 * It returns `[error, data]`.
 * @example
 * const [error, data] = await genericTo(myPromise())
 * @param promise - promise to be awaited for.
 * @param errorExt - error to be appended, in case the promise is rejected.
 */
function genericTo<T>(
  promise: Promise<T>,
  errorExt?: Record<string, unknown>
): Promise<[any, T | undefined]> {
  return promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[any, undefined]>((err: any) => {
      if (errorExt) {
        Object.assign(err, errorExt)
      }

      return [err, undefined]
    })
}

export default genericTo
