/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState, createContext, useContext } from 'react'
import type { PropsWithChildren } from 'react'

import { createSelectable } from 'hooks/useSelectable'
import type { Selectable, SelectableState, SelectableKeyType } from 'hooks/useSelectable'
import type { useSelectableProps } from 'hooks/useSelectable/useSelectable.types'

export type TableSelectableRow = Selectable & {
  value?: string | number | boolean
}

export type TableSelectionSelectable<T extends Selectable = TableSelectableRow> = Omit<
  useSelectableProps<T>,
  'onChange'
> & {
  onChange: (selected: T[]) => void
}

export type TableSelectionConfig<T extends Selectable = TableSelectableRow> =
  | boolean
  | Partial<TableSelectionSelectable<T>>

export interface TableSelectionCore<T extends Selectable = TableSelectableRow> {
  selected?: SelectableState<T>
  config: TableSelectionSelectable<T>
  allRowsAreSelected: boolean
  toggle: (key: T) => void
  toggleAll: () => void
  clear: () => void
  register: (key?: T) => void
}

function getSelectableProps<T extends Selectable = TableSelectableRow>(
  config?: TableSelectionConfig<T>
): TableSelectionSelectable<T> {
  const defaults: TableSelectionSelectable<T> = {
    multiple: true,
    selected: [],
    onChange() {},
    adapters: {
      generic: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getKey: (row) => row.value as string,
      },
    },
  }

  if (!config || config === true) return defaults

  return {
    ...defaults,
    ...config,
  }
}

export function useTableSelectionCore<T extends Selectable>(
  config?: TableSelectionConfig<T>
): TableSelectionCore<T> {
  const { useSelectable } = createSelectable<T>()

  const [registered, setRegistered] = useState<T[]>([])

  const selectableProps = getSelectableProps(config)

  const { selected, toggle, select, unselect, clear } = useSelectable({
    ...selectableProps,
    onChange(value) {
      return selectableProps.onChange(value ? [...value.values()] : [])
    },
  })

  const allRowsAreSelected = selected.size === registered.length

  const getKey = useCallback(
    (row: T): SelectableKeyType => {
      const { adapters } = selectableProps

      const adapter = adapters?.[row._type || 'generic']
      const key = adapter?.getKey(row) ?? ''

      return key
    },
    [selectableProps]
  )

  const register = useCallback(
    (row?: T) => {
      if (!row) return

      const key = getKey(row)

      setRegistered((prev) => {
        const alreadyRegistered = prev.some((prevRow) => getKey(prevRow) === key)

        if (alreadyRegistered) {
          return prev
        }

        return [...prev, row]
      })
    },
    [getKey]
  )

  function toggleAll() {
    if (allRowsAreSelected) {
      registered.forEach((row) => {
        const key = getKey(row)

        if (!key) return

        unselect(key)
      })
    } else {
      registered.forEach((row) => select(row))
    }
  }

  return {
    allRowsAreSelected,
    config: selectableProps,
    selected,
    toggle,
    toggleAll,
    clear,
    register,
  }
}

export const TableSelectionContext = createContext<TableSelectionCore>({
  selected: undefined,
  config: {} as TableSelectionSelectable,
  allRowsAreSelected: false,
  toggle() {},
  toggleAll() {},
  clear() {},
  register() {},
})

type TableSelectionProviderProps = PropsWithChildren<{
  selection?: TableSelectionConfig
}>

export function TableSelectionProvider({
  children,
  selection,
}: TableSelectionProviderProps): JSX.Element {
  const selectionCore = useTableSelectionCore(selection)

  return (
    <TableSelectionContext.Provider value={selectionCore}>
      {children}
    </TableSelectionContext.Provider>
  )
}

export function useTableSelection<
  T extends Selectable = TableSelectableRow
>(): TableSelectionCore<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useContext(TableSelectionContext)
}

type TableSelectionHook<T extends Selectable = TableSelectableRow> = {
  values: T[]
  clear: TableSelectionCore<T>['clear']
}

export function useSelection<T extends Selectable = TableSelectableRow>(): TableSelectionHook<T> {
  const { selected, clear } = useTableSelection<T>()

  return {
    values: selected ? [...selected.values()] : [],
    clear,
  }
}

export function isCellSelected<T extends Selectable>(
  value: T,
  selectedRows: SelectableState<T>,
  config: TableSelectionSelectable<T>
): boolean {
  const { adapters } = config

  const adapter = adapters?.[value._type || 'generic']
  const key = adapter?.getKey(value)

  if (!key) return false

  return selectedRows.has(key)
}

export function useIsCellSelected<T extends Selectable = TableSelectableRow>(value?: T): boolean {
  const { selected, config } = useTableSelection<T>()

  if (!value || !selected) return false

  return isCellSelected(value, selected, config)
}
