import React from 'react'
import styled from 'styled-components'

import { BaseButton } from 'components/Button'
import { getToken as token } from 'theming'
import { Icon } from 'components/Icon'
import conditional, { whenProps } from 'tools/conditional'
import focusable from 'styles/focusable'
import disableable from 'styles/disableable'
import typography from 'styles/typography'

import type { ButtonProps } from 'components/Button'

const Button = styled(BaseButton)`
  user-select: none;

  ${typography(
    conditional({
      'body-bold': whenProps({ 'aria-expanded': true }),
      body: whenProps({ 'aria-expanded': false }),
    })
  )};
  border-color: transparent;
  background-color: transparent;

  color: ${token('color-neutral-dark')};

  ${focusable`
    box-shadow: ${token('button-primary-outline')};
  `}

  ${disableable`
    pointer-events: none;
    opacity: 1;
  `}
`

const Caret = styled(Icon)<{ $rotate: boolean }>`
  ${conditional({
    'transform: rotate(180deg);': whenProps({ $rotate: true }),
  })}
`

function PickerModeToggle(
  props: Omit<ButtonProps & { expanded: boolean }, 'trailing'>
): JSX.Element {
  const { expanded, disabled, ...others } = props

  return (
    <Button
      type="button"
      {...others}
      aria-haspopup="true"
      aria-expanded={expanded}
      disabled={disabled}
      trailing={!disabled && <Caret name="chevron-down" size={16} $rotate={expanded} />}
    />
  )
}

export default PickerModeToggle
