export function check(id: string): void {
  const radioElement = document.getElementById(id) as HTMLInputElement

  if (radioElement) {
    radioElement.checked = true
  }
}

export function getPanelRadioID(id: number | string): string {
  return `rg-wizard-step-radio-${id}`
}
