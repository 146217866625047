/* eslint-disable @typescript-eslint/no-explicit-any */
import flatten from './flatten'
import isEmpty from './isEmpty'
import toArray from './toArray'

// TODO: add this helper to @loadsmart/utils

function interleave<T>(array1: Array<T>, array2: Array<T>): T[] {
  const safeArray1 = toArray(array1)
  const safeArray2 = toArray(array2)

  if (isEmpty(safeArray1) && isEmpty(safeArray2)) {
    return []
  } else if (isEmpty(safeArray1)) {
    return safeArray2
  } else if (isEmpty(safeArray2)) {
    return safeArray1
  }

  return flatten(safeArray1.map((element, index) => [element, safeArray2[index]]))
}

export default interleave
