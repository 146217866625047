import React from 'react'
import type { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import { BaseLink } from 'components/Link'

export type LogoProps = PropsWithChildren<{
  url: string
  className?: string
}>

const Container = styled.div`
  padding-right: ${token('space-l')};
  padding-left: ${token('space-l')};
`

function Logo({ className, url, children }: LogoProps): JSX.Element {
  return (
    <Container className={className}>
      <BaseLink href={url}>{children}</BaseLink>
    </Container>
  )
}

export default styled(Logo)<LogoProps>({})
