export function aliased<T>(aliasMap: Record<number | string, T>, alias: number | string): T {
  return aliasMap[String(alias).toLowerCase()]
}

export const MONTH_BY_ALIAS = {
  0: 'january',
  jan: 'january',
  january: 'january',

  1: 'february',
  feb: 'february',
  february: 'february',

  2: 'march',
  mar: 'march',
  march: 'march',

  3: 'april',
  apr: 'april',
  april: 'april',

  4: 'may',
  may: 'may',

  5: 'june',
  jun: 'june',
  june: 'june',

  6: 'july',
  jul: 'july',
  july: 'july',

  7: 'august',
  aug: 'august',
  august: 'august',

  8: 'september',
  sep: 'september',
  september: 'september',

  9: 'october',
  oct: 'october',
  october: 'october',

  10: 'november',
  nov: 'november',
  november: 'november',

  11: 'december',
  dec: 'december',
  december: 'december',
}

export type CalendarMonthAlias = keyof typeof MONTH_BY_ALIAS

export type CalendarMonth = {
  name: string
  /**
   * Integer value representing the month, beginning with 0 for January to 11 for December.
   * Based on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
   */
  index: number
}

export const MONTH_BY_NAME: Record<string, CalendarMonth> = {
  january: {
    name: 'January',
    index: 0,
  },
  february: {
    name: 'February',
    index: 1,
  },
  march: {
    name: 'March',
    index: 2,
  },
  april: {
    name: 'April',
    index: 3,
  },
  may: {
    name: 'May',
    index: 4,
  },
  june: {
    name: 'June',
    index: 5,
  },
  july: {
    name: 'July',
    index: 6,
  },
  august: {
    name: 'August',
    index: 7,
  },
  september: {
    name: 'September',
    index: 8,
  },
  october: {
    name: 'October',
    index: 9,
  },
  november: {
    name: 'November',
    index: 10,
  },
  december: {
    name: 'December',
    index: 11,
  },
}

function getMonthByAlias(alias: CalendarMonthAlias): CalendarMonth {
  alias = aliased(MONTH_BY_ALIAS, alias) as CalendarMonthAlias

  return MONTH_BY_NAME[alias]
}

function getAllMonths() {
  return (Object.keys(MONTH_BY_NAME) as CalendarMonthAlias[]).map((alias) => {
    return getMonthByAlias(alias)
  })
}

function MonthHelper(
  alias: CalendarMonthAlias
): {
  get(): CalendarMonth
  format(representation?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow'): string
  is(other: CalendarMonthAlias): boolean
} {
  const month = getMonthByAlias(alias)

  return {
    get() {
      return month
    },
    /**
     * Follows same values available in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat.
     *  * "numeric" (e.g., 3)
     *  * "2-digit" (e.g., 03)
     *  * "long" (e.g., March)
     *  * "short" (e.g., Mar)
     *  * "narrow" (e.g., M)
     */
    format(representation: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' = 'long') {
      switch (representation) {
        case 'numeric':
          return String(month.index + 1)
        case '2-digit':
          return String(month.index + 1).padStart(2, '0')
        case 'short':
          return month.name.slice(0, 3)
        case 'narrow':
          return month.name.slice(0, 1)
        default:
          return month.name
      }
    },
    is(other: CalendarMonthAlias) {
      return (
        getMonthByAlias(other) != null &&
        aliased(MONTH_BY_ALIAS, alias) === aliased(MONTH_BY_ALIAS, other)
      )
    },
  }
}

MonthHelper.getAll = getAllMonths

export default MonthHelper
