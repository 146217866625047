import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import ProgressStep from './ProgressStep'

import type { Step } from '../Steps.types'

export interface StepsProgressProps {
  steps: Array<Step>
  current: number | string
}

const ProgressWrapper = styled.ol<{ steps: Step[] }>`
  position: relative;

  display: flex;
  flex-direction: row;

  padding: ${token('steps-progress-padding-y')} ${token('steps-progress-padding-x')};
`

function StepsProgress(props: StepsProgressProps): JSX.Element {
  const { steps, current } = props

  return (
    <ProgressWrapper steps={steps || []}>
      {(steps || []).map(function renderStep(step, index) {
        const { id } = step

        return <ProgressStep key={id} current={current === id} indicator={index + 1} step={step} />
      })}
    </ProgressWrapper>
  )
}

export default StepsProgress
