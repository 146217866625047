import { css } from 'styled-components'
import type { FlattenInterpolation } from 'styled-components'

import { getToken } from 'theming'
import type { ThemedProps, ThemeToken } from 'theming'

interface FontStyleOptions {
  size?: ThemeToken
  family?: ThemeToken
  weight?: ThemeToken
  height?: ThemeToken
  color?: ThemeToken
  transform?: 'uppercase' | 'none'
}

/**
 * Add basic CSS style for default font
 * @returns {FlattenInterpolation}
 */
function fontStyle({
  color = 'color-neutral-darkest',
  weight = 'font-weight-regular',
  size = 'font-size-4',
  family = 'font-family-default',
  height = 'font-height-3',
  transform = 'none',
}: FontStyleOptions): FlattenInterpolation<ThemedProps> {
  return css`
    color: ${getToken(color)};
    font-weight: ${getToken(weight)};
    font-size: ${getToken(size)};
    font-family: ${getToken(family)};
    line-height: ${getToken(height)};
    text-transform: ${transform};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `
}

export default fontStyle
