import React, { forwardRef } from 'react'
import type { AnchorHTMLAttributes, ForwardedRef } from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'

import useSafeLink from './useSafeLink'
import font from 'styles/font'
import transition from 'styles/transition'
import ellipsizable from 'styles/ellipsizable'
import hoverable from 'styles/hoverable'
import disableable from 'styles/disableable'
import { getToken as token } from 'theming'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string
  disabled?: boolean
}

const StyledBaseAnchor = styled.a`
  ${font({
    height: 'link-font-height',
    weight: 'link-font-weight',
  })}
  ${transition()}

  ${ellipsizable()}

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: ${token('link-font-size')};
  color: ${token('color-neutral-darker')};
  text-decoration: none;

  min-height: 24px;

  ${hoverable`
    text-decoration: underline;
    font-weight: ${token('link-font-weight--hover')};
  `}

  ${disableable``}

  ${({ children }) =>
    'string' == typeof children &&
    // to prevent layout shift on hover, https://css-tricks.com/bold-on-hover-without-the-layout-shift/
    css`
      &::after {
        height: 0;
        overflow: hidden;

        font-weight: ${token('link-font-weight--hover')};

        visibility: hidden;

        content: attr(data-text);
        content: attr(data-text) / '';
        user-select: none;
        pointer-events: none;

        @media speech {
          display: none;
        }
      }
    `}
`

export const BaseLink = forwardRef(function BaseLink(
  { disabled = false, ...props }: LinkProps,
  forwardedRef: ForwardedRef<HTMLAnchorElement>
): JSX.Element {
  const { href = '/', children, className, target = '_self', ...others } = useSafeLink(props)

  return (
    <StyledBaseAnchor
      className={clsx(
        {
          'is-disabled': disabled,
        },
        className
      )}
      href={href}
      target={target}
      {...others}
      ref={forwardedRef}
      data-text={'string' == typeof children ? children : ''}
      data-testid="link"
    >
      {children}
    </StyledBaseAnchor>
  )
})

const StyledAnchor = styled(BaseLink)<LinkProps>`
  color: ${token('link-color')};
`

function Link(props: LinkProps): JSX.Element {
  return <StyledAnchor {...props} />
}

export default Link
