import React from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import focusable from 'styles/focusable'
import hoverable from 'styles/hoverable'
import { BaseLink } from 'components/Link'
import conditional, { whenProps } from 'tools/conditional'
import typography from 'styles/typography'

const MenuLink = styled(BaseLink)<{ hasIcon?: boolean }>`
  ${typography('body-bold')}

  color: ${conditional({
    'top-navigation-item-icon-color': whenProps({ hasIcon: true }),
    'top-navigation-item-color': whenProps({ hasIcon: false }),
  })};

  ${hoverable`
    text-decoration: none;
    color: ${token('top-navigation-item-color--hover')};
  `}

  ${focusable`
    color: ${token('top-navigation-item-color--hover')};
  `}
`

type MenuItemIconProps = {
  label: string
  icon?: ReactNode
  url?: string
}

export function MenuItemIcon({ url, icon, label, ...rest }: MenuItemIconProps): JSX.Element {
  if (icon) {
    return (
      <MenuLink {...rest} href={url} hasIcon title={label}>
        {icon}
      </MenuLink>
    )
  }

  return (
    <MenuLink {...rest} href={url}>
      {label}
    </MenuLink>
  )
}
