/**
 * Add basic CSS style for ellipsizing the content of an element.
 * Setting `max-width` is a responsability of the caller.
 * @returns {string}
 */
function ellipsizableStyle(): string {
  return `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
}

export default ellipsizableStyle
