import { createContext, useEffect, useMemo, useReducer, useRef } from 'react'
import { identity } from '@loadsmart/utils-function'

import { useDidMount } from 'hooks/useDidMount'
import createSelectionStrategy from './SelectableStrategy'
import toArray from 'utils/toolset/toArray'
import useFingerprint from 'hooks/useFingerprint'

import type { Context } from 'react'
import type {
  Selectable,
  SelectableAction,
  SelectableKeyType,
  SelectableState,
  SelectableStrategy,
  useSelectableProps,
  useSelectableReturn,
} from './useSelectable.types'

const DEFAULT_ADAPTERS = {}

/**
 * Create a generic manager for collection selection.
 * @returns
 */
function createUseSelectable<T extends Selectable>() {
  return function useSelectable(props: useSelectableProps<T>): useSelectableReturn<T> {
    const { adapters, multiple, onChange } = props

    const didMount = useDidMount()

    const strategy = useMemo<SelectableStrategy<T>>(() => {
      return createSelectionStrategy<T>({
        adapters: adapters || DEFAULT_ADAPTERS,
        multiple: Boolean(multiple),
      })
    }, [adapters, multiple])

    function reducer(state: SelectableState<T>, action: SelectableAction<T>) {
      switch (action.type) {
        case 'select':
          return strategy.select(action.payload, state)
        case 'unselect':
          return strategy.unselect(action.payload, state)
        case 'toggle':
          return strategy.toggle(action.payload, state)
        case 'clear':
          return strategy.clear(state)
        case 'reset':
          return action.payload
      }
    }

    function initializer() {
      return strategy.init(toArray(props.selected))
    }

    const [selected, dispatch] = useReducer(reducer, new Map<SelectableKeyType, T>(), initializer)
    const { resetFingerprint, hasSameFingerprint } = useFingerprint<string>(
      identity,
      [...selected.keys()].map(String)
    )
    const propagateChangeRef = useRef(false)

    function type() {
      return strategy.type()
    }

    function select(items: T | T[]) {
      propagateChangeRef.current = true

      dispatch({
        type: 'select',
        payload: toArray(items),
      })
    }

    function unselect(keys: SelectableKeyType | SelectableKeyType[]) {
      propagateChangeRef.current = true

      dispatch({
        type: 'unselect',
        payload: toArray(keys),
      })
    }

    function toggle(items: T | T[]) {
      propagateChangeRef.current = true

      dispatch({
        type: 'toggle',
        payload: toArray(items),
      })
    }

    function clear() {
      propagateChangeRef.current = true

      dispatch({
        type: 'clear',
      })
    }

    useEffect(
      function handleChange() {
        if (didMount && propagateChangeRef.current) {
          onChange?.(selected)
        }

        propagateChangeRef.current = false
      },
      /**
       * We don't want to update when didMount or the onChange callback changes, because we're only interested
       * in changes to the selected state.
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selected]
    )

    useEffect(
      function updateOnSelectedPropChange() {
        const newSelected = initializer()
        const keys = [...newSelected.keys()].map(String)

        if (hasSameFingerprint(keys)) {
          return
        }

        propagateChangeRef.current = false

        resetFingerprint(keys)
        dispatch({
          type: 'reset',
          payload: newSelected,
        })
      },
      /**
       * initializer is not relevant for our changes, that's why its ommitted.
       */
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.selected, hasSameFingerprint, resetFingerprint]
    )

    return { type, selected, select, unselect, toggle, clear }
  }
}

export function createSelectable<T extends Selectable>(): {
  SelectableContext: Context<useSelectableReturn<T>>
  useSelectable: (props: useSelectableProps<T>) => useSelectableReturn<T>
} {
  const SelectableContext = createContext<useSelectableReturn<T>>({} as useSelectableReturn<T>)
  const useSelectable = createUseSelectable<T>()

  return { SelectableContext, useSelectable }
}
