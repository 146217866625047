import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'

import MenuExpandable from './MenuExpandable'
import type { MenuExpandableProps } from './MenuExpandable'
import MenuLink from './MenuLink'
import type { MenuLinkProps } from './MenuLink'
import useID from 'hooks/useID'
import { styledCompounds } from 'utils/toolset/styledCompounds'

const MenuTitle = styled.div`
  padding: ${token('space-s')} 0;
  padding-left: ${token('space-l')};

  color: ${token('color-neutral')};
  font-weight: ${token('font-weight-bold')};
  font-size: ${token('font-size-5')};
  line-height: ${token('font-height-3')};
  text-transform: uppercase;
`

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
}

function Menu({ label, children, ...props }: MenuProps): JSX.Element {
  const menuId = useID()

  return (
    <div role="menubar" aria-labelledby={menuId} {...props}>
      {label && <MenuTitle id={menuId}>{label}</MenuTitle>}

      {children}
    </div>
  )
}

type MenuItemProps = { active?: boolean } & PropsWithChildren<MenuLinkProps & MenuExpandableProps>

function MenuItem(props: MenuItemProps) {
  if (props.children) {
    return <MenuExpandable {...props} />
  }

  return <MenuLink {...props} />
}

const StyledMenu = styled(Menu)({})

export default styledCompounds(StyledMenu, { Item: MenuItem, SubItem: MenuLink })
