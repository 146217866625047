import { css } from 'styled-components'

import type { FlattenInterpolation } from 'styled-components'
import type { ThemedProps } from 'theming'

import conditional, { whenProps } from 'tools/conditional'

export const getSpace = (prop: string): FlattenInterpolation<ThemedProps> => css`
  ${conditional({
    'space-none': whenProps({ [prop]: 'none' }),
    'space-2xs': whenProps({ [prop]: '2xs' }),
    'space-xs': whenProps({ [prop]: 'xs' }),
    'space-s': whenProps({ [prop]: 's' }),
    'space-m': whenProps({ [prop]: 'm' }),
    'space-l': whenProps({ [prop]: 'l' }),
    'space-xl': whenProps({ [prop]: 'xl' }),
    'space-2xl': whenProps({ [prop]: '2xl' }),
    'space-3xl': whenProps({ [prop]: '3xl' }),
  })}
`
