import get from 'utils/toolset/get'

import type { SelectAdapter } from './Select.types'

export const GenericAdapter: SelectAdapter<unknown> = {
  getKey(o): string {
    return get(o, 'value') as string
  },
  getLabel(o): string {
    return get(o, 'label') as string
  },
}
