import React, { BaseHTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Icon } from 'components/Icon'
import { getToken as token } from 'theming'
import conditional, { whenProps } from 'tools/conditional'
import Status from 'utils/types/Status'

import DefaultCloseButton from 'common/CloseButton'

import { ProgressBar } from 'components/ProgressBar'

type ContainerProps = Pick<ToastProps, 'status'>
const Container = styled.div<ContainerProps>`
  position: relative;

  width: ${token('toast-width')};
  max-width: 100%;
  padding: ${token('toast-padding-y')} ${token('toast-padding-x')};
  overflow: hidden;

  color: ${token('toast-font-color')};
  font-weight: ${token('toast-font-weight')};
  font-size: ${token('toast-font-size')};
  line-height: ${token('toast-font-height')};

  background: ${conditional({
    'toast-neutral-background': whenProps({ status: Status.Neutral }),
    'toast-success-background': whenProps({ status: Status.Success }),
    'toast-warning-background': whenProps({ status: Status.Warn }),
    'toast-danger-background': whenProps({ status: Status.Danger }),
  })};
  border-radius: ${token('toast-border-radius')};
  box-shadow: ${token('toast-shadow')};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Leading = styled.span`
  position: absolute;
`

const ToastCountDown = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  left: 0;
`
const CloseButton = styled(DefaultCloseButton)`
  position: absolute;

  top: ${token('toast-close-y')};
  right: ${token('toast-close-x')};

  color: ${token('color-neutral-white')};
`

export interface ToastProps extends BaseHTMLAttributes<HTMLDivElement> {
  message: string
  status?: Status
  timeout?: number
  showIcon?: boolean
  onClose: () => void
}

const INTERVAL = 300 //ms

function Toast({
  message,
  onClose,
  timeout,
  showIcon = false,
  status = Status.Neutral,
}: ToastProps): JSX.Element {
  const [count, setCount] = useState(100)

  /**
   * this is a quick solution for the progress/countdown effect,
   * we can use requestAnimationFrame or some other library to
   * better implement this in the future
   */
  useEffect(() => {
    if (!timeout) return

    const decrementBy = (INTERVAL * 100) / timeout
    const interval = window.setTimeout(() => {
      if (count <= 0) {
        window.clearTimeout(interval)
        onClose()
      } else {
        setCount((value) => value - decrementBy)
      }
    }, INTERVAL)

    return () => {
      window.clearTimeout(interval)
    }
  }, [count, onClose, timeout])

  return (
    <Container status={status} aria-live="polite" role="alert">
      {showIcon && (
        <Leading>
          <ToastIcon status={status} />
        </Leading>
      )}
      <Content>{message}</Content>
      {Boolean(timeout) && <ToastCountDown value={count} status={status} />}
      {onClose && <CloseButton onClick={onClose} size={14} />}
    </Container>
  )
}

interface ToastIconProps {
  status: Status
}

function ToastIcon({ status }: ToastIconProps) {
  let iconName = ''

  switch (status) {
    case Status.Success:
      iconName = 'check'
      break

    case Status.Warn:
    case Status.Danger:
      iconName = 'warning'
      break

    default:
      iconName = 'information'
  }

  return <Icon name={iconName} size={20} />
}

export default Toast
