import CloseButton from 'common/CloseButton'
import type { CloseButtonProps } from 'common/CloseButton'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { getToken as token } from 'theming'

import { Logo } from './Logo'
import { Menu } from './Menu'
import { Separator } from './Separator'

const Container = styled.aside`
  z-index: ${token('z-index-overlay')};
  /* this was needed to force the side-navigation-width inside flex containers */
  flex: 0 0 ${token('side-navigation-width')};
  width: ${token('side-navigation-width')};
  min-height: 100vh;
  padding-top: ${token('space-l')};
  padding-bottom: ${token('space-l')};
  overflow: auto;

  background-color: ${token('side-navigation-background-color')};

  ${Logo} + * {
    margin-top: ${token('space-xl')};
  }

  ${Menu} + ${Menu} {
    margin-top: ${token('space-m')};
  }
`

export interface SideNavigationProps {
  className?: string
  children: ReactNode
}

function SideNavigation({ className, children }: SideNavigationProps): JSX.Element {
  return <Container className={className}>{children}</Container>
}

SideNavigation.Logo = Logo
SideNavigation.Menu = Menu
SideNavigation.Separator = Separator
SideNavigation.CloseButton = styled(CloseButton)<CloseButtonProps>({})

export default SideNavigation
