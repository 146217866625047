import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'

import type { HTMLAttributes } from 'react'

export type PopoverProps = HTMLAttributes<HTMLDivElement>

const PopoverWrapper = styled.div`
  background: ${token('popover-background')};
  border: 1px solid ${token('popover-border-color')};
  border-radius: ${token('popover-border-radius')};
  box-shadow: ${token('popover-shadow')};
`

function Popover({ children, ...others }: PopoverProps): JSX.Element {
  return <PopoverWrapper {...others}>{children}</PopoverWrapper>
}

export default Popover
