import React from 'react'

import { IconButton } from 'components/Button'
import { Icon } from 'components/Icon'

import type { ButtonProps } from 'components/Button'

export type BackButtonProps = Omit<ButtonProps, 'children'>

function BackButton({ scale = 'small', ...others }: BackButtonProps): JSX.Element {
  return (
    <IconButton scale={scale} {...others}>
      <Icon name="back" size={16} />
    </IconButton>
  )
}

export default BackButton
