import React from 'react'
import type { PropsWithChildren, ReactNode, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'
import {
  MenuItemDropdown,
  MenuDropdown,
  MenuDropdownSubItem,
  MenuDropdownSeparator,
} from './MenuItemDropdown'
import { MenuItemIcon } from './MenuItemIcon'
import conditional, { whenProps } from 'tools/conditional'

type MenuItemProps = HTMLAttributes<HTMLElement> &
  PropsWithChildren<{
    label: string
    icon?: ReactNode
    url?: string
  }>

export type MenuProps = PropsWithChildren<{
  align?: 'left' | 'right'
}>

const MenuContainer = styled.nav<{ $align: MenuProps['align'] }>`
  ${conditional({
    'margin-left: auto;': whenProps({ $align: 'right' }),
  })}

  display: flex;
  flex-direction: row;
  align-items: center;

  & > * + * {
    margin-left: ${token('space-m')};
  }

  & > * + ${MenuDropdown} {
    margin-left: ${token('space-xl')};
  }
`

function MenuItem(props: MenuItemProps): JSX.Element {
  if (props.children) {
    return <MenuItemDropdown {...props} />
  }

  return <MenuItemIcon {...props} />
}

function Menu(props: MenuProps): JSX.Element {
  const { align = 'left' } = props

  return <MenuContainer $align={align}>{props.children}</MenuContainer>
}

Menu.Item = MenuItem
Menu.SubItem = MenuDropdownSubItem
Menu.Separator = MenuDropdownSeparator

export default Menu
