import React, { HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import font from 'styles/font'
import { getToken as token } from 'theming'

export type SectionProps = HTMLAttributes<HTMLElement>

const StyledSection = styled.section`
  ${font({
    height: 'font-height-2',
  })}
`

const StyledSectionTitle = styled.section`
  ${font({
    weight: 'font-weight-bold',
    height: 'font-height-2',
  })}

  font-size: ${token('font-size-3')};
  color: ${token('color-neutral-darker')};

  border-bottom: 2px solid ${token('color-neutral-light')};

  padding: ${token('space-s')} ${token('space-xs')};
  margin-bottom: ${token('space-l')};
`

const StyledSpan = styled.span`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  pointer-events: none;
`

const Leading = styled(StyledSpan)`
  margin: 0 ${token('button-spacing-x')} 0 0;
`

export interface SectionTitleProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode
  leading?: ReactNode
}

function SectionTitle({ children, leading, ...others }: SectionTitleProps) {
  return (
    <StyledSectionTitle data-testid="section-title" {...others}>
      {leading && <Leading>{leading}</Leading>}
      {children}
    </StyledSectionTitle>
  )
}

function Section({ children, ...others }: SectionProps): JSX.Element {
  return (
    <StyledSection data-testid="section" {...others}>
      {children}
    </StyledSection>
  )
}

Section.Title = SectionTitle

export default Section
