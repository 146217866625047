function compare(...args: any[]): unknown {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [value, defaultValue] = args

  if (value == null || value === false || Number.isNaN(value)) {
    return defaultValue || value
  }

  if (typeof value === 'string' && value.length === 0) {
    return defaultValue || value
  }

  return value
}

/**
 * Retrieve the key value from the props object
 * @example
 * ```jsx
 * -transform: scaleY(${(props) => props.$height || 1});
 * +transform: scaleY(${prop('$height', 1)});
 * ```
 * @param name a valid property name from the object
 * @param defaultValue a fallback value in case the property value is invalid
 * @param comparatorFn a function to be used to decide between value or defaultValue
 * @returns {(props: ThemedProps) => string} Returns function that consumes component props.
 */
function prop<P, K extends keyof P = keyof P>(
  name: K,
  defaultValue?: NonNullable<P[K]>,
  comparatorFn = compare
) {
  return (props: P): P[K] => comparatorFn(props[name], defaultValue) as P[K]
}

export default prop
