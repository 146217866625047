import React from 'react'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { getToken as token } from 'theming'
import hoverable from 'styles/hoverable'
import MenuBaseItem from './MenuBaseItem'

const StyledMenuItem = styled((props) => <MenuBaseItem as="a" {...props} />)`
  ${hoverable`
    color: ${token('color-primary')};
  `}

  ${({ active }) =>
    active
      ? css`
          color: ${token('color-primary')};

          background-color: rgba(0, 0, 0, 0.2);
          box-shadow: inset ${token('space-xs')} 0px ${token('color-primary')};
        `
      : ''}
`

export type MenuLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  label: string
  leading?: ReactNode
  trailing?: ReactNode
  url?: string
}

function MenuLink({ url, ...props }: MenuLinkProps): JSX.Element {
  return <StyledMenuItem href={url} {...props} role="menuitem" />
}

export default styled(MenuLink)({})
