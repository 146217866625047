import { css, ThemedStyledProps } from 'styled-components'
import type { FlattenInterpolation } from 'styled-components'
import { isFunction } from '@loadsmart/utils-function'

import conditional from 'tools/conditional'
import type { CustomTheme, ThemeToken } from 'theming'
import fontStyle from './font'

export type TypographyOptions = {
  italic?: boolean
  color?: ThemeToken
}

type PropertiesToken = {
  fontSize: ThemeToken
  lineHeight: ThemeToken
  fontWeight: ThemeToken
  fontFamily?: ThemeToken
  textTransform?: 'none' | 'uppercase'
  color?: ThemeToken
}

export type TypographyVariants =
  | 'heading-lg'
  | 'heading-md'
  | 'heading-md-bold'
  | 'heading-sm'
  | 'heading-sm-bold'
  | 'body'
  | 'body-bold'
  | 'caption'
  | 'caption-bold'
  | 'number-sm'
  | 'number-md'
  | 'button-sm'
  | 'button-md'
  | 'button-lg'
  | 'table-header-sm'
  | 'table-header-md'
  | 'chips-sm'
  | 'link'

type TypographyProperties = Record<TypographyVariants, PropertiesToken>

export const typographyProperties: TypographyProperties = {
  'heading-lg': {
    fontSize: 'font-size-1',
    lineHeight: 'font-height-1',
    fontWeight: 'font-weight-bold',
  },
  'heading-md': {
    fontSize: 'font-size-2',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-medium',
  },
  'heading-md-bold': {
    fontSize: 'font-size-2',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-bold',
  },
  'heading-sm': {
    fontSize: 'font-size-3',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-medium',
  },
  'heading-sm-bold': {
    fontSize: 'font-size-3',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-bold',
  },
  body: {
    fontSize: 'font-size-4',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-medium',
  },
  'body-bold': {
    fontSize: 'font-size-4',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-bold',
  },
  caption: {
    fontSize: 'font-size-5',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-medium',
  },
  'caption-bold': {
    fontSize: 'font-size-5',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-bold',
  },
  'number-sm': {
    fontFamily: 'font-family-monospace',
    fontSize: 'font-size-5',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-medium',
  },
  'number-md': {
    fontFamily: 'font-family-monospace',
    fontSize: 'font-size-4',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-medium',
  },
  'button-sm': {
    fontSize: 'font-size-5',
    lineHeight: 'font-height-1',
    fontWeight: 'font-weight-bold',
    textTransform: 'uppercase',
  },
  'button-md': {
    fontSize: 'font-size-4',
    lineHeight: 'font-height-1',
    fontWeight: 'font-weight-bold',
    textTransform: 'uppercase',
  },
  'button-lg': {
    fontSize: 'font-size-3',
    lineHeight: 'font-height-1',
    fontWeight: 'font-weight-bold',
    textTransform: 'uppercase',
  },
  'table-header-sm': {
    fontSize: 'font-size-5',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-bold',
  },
  'table-header-md': {
    fontSize: 'font-size-4',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-bold',
    textTransform: 'uppercase',
  },
  'chips-sm': {
    fontSize: 'font-size-6',
    lineHeight: 'font-height-3',
    fontWeight: 'font-weight-bold',
    textTransform: 'uppercase',
  },
  link: {
    fontSize: 'font-size-4',
    lineHeight: 'font-height-2',
    fontWeight: 'font-weight-medium',
    color: 'color-accent',
  },
}

const DEFAULT_VARIANT: TypographyVariants = 'body'

function getPropertiesByVariant(variant: TypographyVariants) {
  return variant in typographyProperties
    ? typographyProperties[variant]
    : typographyProperties[DEFAULT_VARIANT]
}

type StyledTemplate<P> = FlattenInterpolation<ThemedStyledProps<P, CustomTheme>>

/**
 * Standard styles for screen-rendered text
 * @param {TypographyVariants} variant Name of the variant
 * @param {TypographyOptions} options Add boldness, italics and color
 * @returns {FlattenInterpolation}
 */
function typography<P>(
  variant: TypographyVariants | ((props: P) => string) = DEFAULT_VARIANT,
  options?: TypographyOptions
): StyledTemplate<P> | ((props: P) => StyledTemplate<P>) {
  if (isFunction(variant)) {
    return ((props: P) => typography(variant(props) as TypographyVariants, options)) as (
      props: P
    ) => StyledTemplate<P>
  }

  const properties = getPropertiesByVariant(variant)

  return css`
    ${fontStyle({
      family: properties.fontFamily,
      height: properties.lineHeight,
      size: properties.fontSize,
      weight: properties.fontWeight,
      transform: properties.textTransform,
      color: properties.color || options?.color,
    })}

    ${conditional({
      'font-style: italic;': options?.italic,
    })};
  `
}

export default typography
