import React from 'react'
import styled from 'styled-components'

import { getToken as token } from 'theming'

const Line = styled.hr`
  margin: ${token('side-navigation-component-margin-top')} 0;

  color: ${token('side-navigation-separator-color')};
`

export interface SeparatorProps {
  className?: string
}

function Separator(props: SeparatorProps): JSX.Element {
  return <Line {...props} role="separator" />
}

export default Separator
