import { createContext } from 'react'

interface TabContextValue {
  id: string
  activeTab: string
  setActiveTab: (name: string) => void
  direction?: 'horizontal' | 'vertical'
}

export const TabContext = createContext<TabContextValue>({} as TabContextValue)
